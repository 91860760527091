@use '../../styles/config/colors' as *;
@use '../../styles/mixins' as *;

.form {

    section {
        aside {
            width: 60px;
            flex-shrink: 0;
            color: #333;
            display: block;
            border-right: solid 2px $blue-dark;
            margin: 0 20px 0 0;
            padding: 0 0 0 10px;

            &.warn {
                border-color: $red-validation;

                div {
                    background-color: $pink-dark-border;
                    color: $white;
                }
            }

            &.imgaside {
                svg {
                    color: #fff;
                }
            }

            div {
                display: block;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1em;
                background-color: $blue-dark;

                span {

                    color: $white;
                }
            }

        }
    }

    &.demande {
        background-color: $orange-extra-light;
    }
}

.radios {
    display: flex;
    margin: 15px 0 8px 0;

    >div {
        margin-right: 10px;
    }
}

.formsizer {
    overflow: auto;
    padding-right: 10px;
    height: calc(100vh - 197px);
}

.buttons {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-top: solid 1px #eee;

    button {
        margin-left: 5px;
    }

    .imgicon {
        display: none;
    }
}

.buttons2 {
    display: flex;
    justify-content: space-between;
}

.filesheading {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 20px;
}

.addimagebtn {
    background-color: $blue;
    border: none;
    font-size: 1.6em;
    padding: 3px 5px;

    cursor: pointer;

    border: solid 1px $blue;
    border-radius: 6px;

    &:active {
        filter: brightness(80%);
    }

    &:hover {
        filter: brightness(115%);
    }

    svg {
        margin-right: 5px;
        color: #fff;

        &:last-child {
            margin-right: 0;
        }
    }
}

.new {

    display: inline-block;
    background-color: #124492;
    padding: 6px;
    margin: 5px 0;
    border-radius: 3px;
    font-size: .9em;

    strong {
        font-weight: normal;
        color: #e5ff00;
        margin: 0 5px 0 0;
    }

    span {
        color: #fff;
    }
}

.u {
    display: flex;
    justify-content: flex-end;
}

.imagesform {

    height: inherit;
    display: flex;
    flex-direction: column;
}

.imagecontainer {
    height: calc(100vh - 253px);

}

.categoryInfo {
    margin: 10px 0 0;
    display: flex;

    border: solid 1px #a3b1c9;
    background-color: #f4f7fc;
    padding: 5px;
    border-radius: 8px;

    svg {
        display: block;
        margin: 0 10px 0 0;
    }

    p {
        font-size: 1em;
    }

    span {
        font-size: .8em;
    }
}

.overflow {
    height: calc(100vh - 298px);
    overflow: auto;
}

.images {

    >div {
        display: flex;
        flex-wrap: wrap;
    }

    .image {
        position: relative;
        margin: 0 5px 5px 0;
        cursor: move;

        width: 200px;
        height: 200px;
        padding: 6px;

        @include shadow-1dp;

        border-radius: 5px;

        img {

            user-select: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .h {
            top: 5px;
            right: 5px;
            position: absolute;


            display: flex;
            justify-content: flex-end;
            opacity: .45;
            transition: all linear .3s;

            svg {
                border-radius: 50%;
                color: #fff;
                background-color: $red-validation;
                padding: 8px;
                cursor: pointer;
                font-size: 1.1em;
            }
        }

        &:hover {
            .h {
                opacity: 1;
            }

        }
    }
}

.notification {
    margin: 10px 0;
    border: solid 1px #FF6E07;
    background-color: #ffecdf;
    border-radius: 5px;
    padding: 6px;

    &.error {
        border: solid 1px $pink-dark;
        background-color: $notice-light-red;

        p {
            color: #ff2800;
        }
    }

    p {
        font-weight: normal;
        margin: 0 !important;
    }
}

.savechanges {
    margin: 8px 0 0 0;

    display: flex !important;
    align-items: center;

    svg {
        color: #fff;
        font-size: 1.4em;
        margin-right: 5px;
    }

    span {
        color: #fff;
    }
}

@media screen and (max-width: 720px) {
    .overflow {
        height: calc(100vh - 318px);
    }
}

@media screen and (max-width: 600px) {

    .form {
        padding: 0;

        section {
            aside {

                padding: 0;

                position: absolute;
                display: block;
                border: none;

                div {
                    height: 20px;
                    width: 20px;
                    font-size: .6em;
                    margin: 0;
                    padding: 0;
                }

                &.imgaside {
                    position: relative;
                    margin: 0 0 10px;
                }
            }
        }

        >div {
            &:first-child {
                padding: 5px !important;
            }

        }
    }


    .buttons2 {
        .returnbtn {
            span {
                display: none;
            }

            svg {
                margin: 0 !important;
            }
        }
    }

    .buttons {
        background-color: #eee;
        justify-content: space-between;
        border-top: solid 1px #eee;
        padding: 5px 3px;

        button {
            margin-left: 5px;
        }

        .deletebtn,
        .returnbtn {
            span {
                display: none;
            }

            svg {
                margin: unset !important;
            }
        }


        .toimages {
            span {
                display: none;
            }
        }

        .imgicon {
            display: inline-block;
        }
    }

    .formsizer {
        height: calc(100vh - 142px);
        padding: 5px;
    }

    .imagecontainer {
        height: calc(100vh - 197px);
    }

    .overflow {
        height: calc(100vh - 211px);

    }

    .images {

        display: block;

        .image {
            width: 190px;
            height: 190px;
            margin: 0 0 5px;

            &:last-child {
                margin-bottom: 0;
            }

            img {}

        }
    }
}

@media screen and (max-width: 500px) {

    .overflow {
        height: calc(100vh - 215px);
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 400px) {

    .overflow {
        height: calc(100vh - 236px);
        margin-bottom: 5px;
    }
}