@use '../../styles/config/colors' as *;

.container {
    padding: 20px;
    background-color: #f0f0f0;
    
    &.readonly {
        height: calc(100vh - 91px);
        overflow: auto;
    }

    .document {
        padding: 20px;
        background-color: #fff;
        margin: 0 auto;
        max-width: 1000px;

        .title {
            h1 {
                font-size: 1.5em;
                font-weight: normal;
                color: $blue-dark;
                text-align: center;
                margin-bottom: 20px;
            }
        }


        .body {
            line-height: 1.3em;

            header {
                text-align: center;

                h1 {
                    color: #E4AD4A;
                    font-size: 1.4em;
                    font-weight: normal;
                    margin: 0 0 20px;
                }
            }

            section {
                margin: 0 0 40px;

                h2 {
                    color: $blue-dark;
                    font-weight: normal;
                    font-size: 1.3em;
                    margin: 0 0 10px;
                }

                h3 {
                    font-size: 1em;
                    margin: 0 0 10px;
                }

                p {
                    margin: 0 0 20px;
                }

                ul {
                    margin: 0 0 10px 10px;

                    li {
                        margin: 0 0 3px 20px;
                        font-size: .9em;
                    }
                }
            }
        }

        .controls {
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
                margin-bottom: 15px;
            }

            a {
                font-size: .84em;
            }
        }
    }
}

@media screen and (max-width: 600px) {


    .container {
        padding: 1px 0 0 0;

        .document {
            font-size: .95em;
            max-width: unset;


            .body {
                line-height: 1.2em;

            }
        }
    }
}