@use "../../../styles/config/colors" as *;

.container {
    padding: 10px;

    .alert {
        background-color: #dfe4e0;
        display: block;
        padding: 10px;
        border-radius: 5px;
        color: $btn-green;
    }

    .form {
        max-width: 600px;

        pre {
            font-size: .9em;
            margin: 4px 0 0 5px;
            color: $red-validation;
        }
    }

    .warning {
        background-color: $red-validation-background !important;
        border-color: $red-validation !important;
    }

    .heading {
        display: flex;
        align-items: center;

        h1 {
            font-size: 1.8rem;
            color: $blue-dark;
            font-weight: normal;
        }

        a {
            font-size: 1.5em;
            color: $btn-green;
            margin-left: 10px;
        }

    }

    .opt {
        margin: 0 0 10px;
        max-width: 500px;

        display: flex;

        .lang {
            margin-right: 10px;
            width: 50%;
            flex-shrink: 0;
        }

        .filter {
            padding: 0 0 2px 0;
            width: 100%;
            margin-right: 10px;
            display: flex;

            input {
                border: solid 1px #ccc;
                border-radius: 5px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            button {
                border: solid 1px #ccc;
                border-radius: 5px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: none;
                cursor: pointer;
            }
        }
    }

    .control {

        height: calc(100vh - 201px);
        background-color: #f0f0f0;
        padding: 5px;
        overflow: auto;

        .entry {
            &:first-child {
                border-top: none;
            }

            &:last-child {
                border-bottom: none;
            }

            padding: 10px;
            border-top:solid 1px #fff;
            border-bottom: solid 1px #bbb;
            align-items: center;

            span {
                display: block;
                font-size: .72em;
                color: #bbb;
            }

            input {
                width: 100%;
                padding: 6px;
                margin: 0 0 6px;
                border: solid 1px #7c8a96;
                border-radius: 4px;

                &:read-only {
                    background-color: #ddd;
                    color: #455;
                }
            }

            .subcontrols {
                display: flex;
                align-items: center;

                span {
                    margin-left: 20px;
                }

                button {
                    cursor: pointer;
                    background: none;
                    border: none;
                    color: rgb(26, 137, 201);

                    &:hover {
                        filter: brightness(110%);
                    }

                    svg {
                        &.save {
                            color: $btn-green;
                        }

                        &.delete {
                            color: $pink-dark;
                        }

                        &.back {
                            color: $pink-dark;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .container {

        .control {
            height: calc(100vh - 180px);
        }

        .opt {
            width: 100%;

            .filter {
                margin-right: 5px;

                input {
                    width: 90%;
                }
            }
        }
    }
}