@use "../../../styles/config/colors" as *;

.container {
    .heading {
        padding: 10px;

        h3 {
            font-size: 1.3em;
            font-weight: normal;
            color: $blue-dark;
        }
    }

    .list {
        height: calc(100vh - 217px);
        overflow: auto;
        margin-top: 20px;
    }

    .groupname {
        display: flex;
        align-items: flex-start;

        button {
            background: transparent;
            border: none;
            cursor: pointer;

            svg {
                color: $blue-pen;

                &:hover {
                    filter: brightness(150%);
                }
            }
        }
    }

    .groupnameedit {
        max-width: 400px;

        .buttons {
            margin: 5px 0 0 0;
            display: flex;

            button {
                &:first-child {
                    margin: 0 5px 0 0;
                }
            }
        }
    }

    .groupform {
        max-width: 600px;
    }

    .collection {
        margin: 20px 0 0 0;
        height: calc(100vh - 511px);
        overflow: auto;

        .h {
            display: flex;
            justify-content: space-between;

            button {
                svg {
                    margin: 0;
                }

                span {
                    margin: 0 0 0 5px;
                }
            }
        }

        ul {
            margin: 10px 0 0 0;

            li {
                margin: 0 0 0 20px;
                line-height: 1.6em;
            }
        }
    }

    .user {
        .ug {
            display: flex;
            align-items: center;
        }

        a {
            font-size: .8em;
            display: inline-block;
            margin-left: 5px;
            color: $blue-pen;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            &.remove {
                color: $red-validation;
            }
        }
    }

    .tags {
        display: flex;
        margin: 3px 0 15px 0;

        span {
            display: block;
            font-size: .75em;
            padding: 4px 5px;
            background-color: #617e8a;
            color: #fff;
            border-radius: 6px;
            margin: 0 5px 0 0;

            &.g {
                background-color: #e65400;
            }
        }
    }

    .email {
        span {
            font-size: .82em;
            color: #5f0991;
        }
    }

    .labelgroup {
        display: flex;
        align-items: center;

        label {
            margin: 0 5px 0 0;
        }

        margin-bottom: 10px;
    }

    .inputgroup {
        display: flex;
        align-items: center;

        input {
            margin: 0;
        }

        button {
        margin-left: 5px;}

        margin-bottom: 10px;
    }
}