@use "../../styles/config/colors" as *;

.login {
    display: block;
    max-width: 600px;
    width: 600px;
    margin: 0 auto;

    a {
        cursor: pointer;
        font-size: .82em;
        color: $blue-pen;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
     
    input[type="text"] {
        border-color: $blue;
    }

    .loginrow {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0 0px;
        
        .loginbtn{
            padding: 7px 10px;
            font-size: 1em;
        }
    }

    .registerrow {
        display: flex;
        flex-direction: row;
        margin-bottom:50px;
        
        justify-content: end;
        .signupbtn {
            text-decoration: none !important;
            padding: 7px 10px;
            font-size: 1em;
        }
    }

}

.usecode {
    display: flex;
    justify-content: space-between;

}

.loading {
    margin: 100px auto 0;
    width: 40px;
}

.ok {
    margin: 200px auto 0;
}

.warning {
    margin-top: 50px;
}

.labelhelp {
    display: flex;
    justify-content: space-between;

}



.resetform {
    h1 {
        margin-bottom: 30px;
    }
}

.emailinput {
    border: solid 1px #ccc;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 4px;

    &.inputwarn {
        border-color: $pink-dark;
    }

    input {
        width: 100%;
        border: none;
    }

    .icon {
        flex-shrink: 0;
        width: 20px;
        margin-right: 8px;

        svg {
            color: $btn-green;
            font-size: 1.4em;

            &.chevronBtn {
                cursor: pointer;
            }
        }
    }
}


@media screen and (max-width: 680px) {
    .login {
        width: 100%;
        margin: unset;
    }
}