div.ck-content{
    ul{
        margin-left: 10px;
        li{
            margin: 0 0 0 8px;    
        }   
    }
    ol{
        margin-left: 15px;
    }
    h2{
        font-size: 2em;
    }
    h3{
        font-size: 1.6em;
    }
    h4{
        font-size: 1.2em;
    }
}