@use "../../styles/config/colors" as *;

.container {

    max-width: 800px;
    margin: 60px auto;

    h1 {
        font-size: 2em;
        color: $blue-dark;
        font-weight: normal;
        margin-bottom: 40px;
        text-align: center;
    }

    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 50px auto;

        &.code{
            max-width: 600px;
        }

        .codeInput {
 
            padding: 8px;
            font-size: 1.2em;
 
            padding: 6px 0 6px 6px;
            border: solid 1px #bbb;
            background-color: #fff;
            border-radius: 5px;
            font-size: 0.92em;
            color: #171096;
            outline: none;
            width: 250px;
            min-width: 80px;

           
            display: flex;
            align-items: center;

            input{
                border:none;
                width: 100%;
            }
            svg{
                flex-shrink: 0;
                color:$btn-green;
                cursor: pointer;
            }

            >div{
                padding: 10px 15px 10px 10px;
                cursor: pointer;
            }

            &.fail{
                border-color: $pink-dark-border;
                svg{
                    color:$pink-dark-border;
                }
                input{
                    color:$pink-dark-border;
                }
            }

            margin:0 0 20px
        }
    }


    .notice{
        font-size: .8em;
        margin-bottom: 20px;
    }
    p {
        line-height: 1.5em;
        font-size: 1.2em;
        margin-bottom: 15px;

        &.sm {
            font-size: .92em;
        }
    }

    .return{ 
        a{
            text-decoration: none;
            &:hover{
                color:#028ae6;
            }
        }
        
    }
}


@media screen and (max-width: 1000px) {
    .container {
        margin: 30px auto;
        padding: 20px;

        .row {
            .codeInput {}
        }
    }
}

@media screen and (max-width: 600px) {
    .container {

        .row {
            .codeInput {
                width: auto;
            }
           
        }
    }
}