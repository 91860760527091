@use "../styles/config/colors" as *;

.container{
    display: flex;
    align-items: center;
    height: 37px;
    background-color: $blue;
    padding: 10px 10px 11px;

    span{
        flex-shrink: 0;
        color: $blue-dark;       
        font-size: .8em;
    }
    >div{
        display: flex;
        width: 100%;
        justify-content: center;

        a{
            text-decoration: none;
            color: $blue-dark;
            font-size: .9em;
            &:hover{
                text-decoration: underline;
            }
        }
    }
} 

@media screen and (max-width: 600px) {
    .container{
        height: unset;
        padding: 5px;
        span{
            display: none;
        }
        >div{
            a{
                font-size: .84em;
            }
        }

    }
}