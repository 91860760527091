@use "../../styles/config/colors" as *;

.container {
    a {
        display: block;
        text-align: right;
        font-size: .8em;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        margin-bottom: 5px;
    }
    
    .resetform {
        width: 500px;
        h1 {
            margin-bottom: 30px;
        }
    }

    .loading {
        margin: 100px auto 0;
        width: 40px;
    }

    .buttonrow {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .emailinput {
        border: solid 1px #ccc;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 4px;

        &.inputwarn {
            border-color: $pink-dark;
        }

        input {
            width: 100%;
            border: none;
        }

        .icon {
            flex-shrink: 0;
            width: 20px;
            margin-right: 8px;

            svg {
                color: $btn-green;
                font-size: 1.4em;

                &.chevronBtn {
                    cursor: pointer;
                }
            }
        }
    }
}