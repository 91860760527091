@use "../../styles/config/colors" as *;
@import '../../styles/mixins';

.container {

    .baloon {
        @include shadow-active-2pd;
        position: absolute;
        z-index: 300;
        min-height: 40px;
        min-width: 180px;
        background-color: #ececec;

        &::before {
            content: ' ';
            z-index: 303;
            position: absolute;
            width: 0;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $notice-light-green;
            transform: translate(0, -8px);
        }

        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;

        article {
            padding: 10px;
            font-size: .82em;
            color:#333;

            &>div{
                padding:2px 0;
            }

            .overflow{
                max-height: 250px;
                overflow: auto;
            }
        }

        transform: translate(5px, 40px);
    }

}