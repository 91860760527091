.control {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: relative;

    &:hover {
        // background drop shadow a bit spread
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    }

    .f {
        position: absolute;
        transform: translate(-40px, 0);

        svg {
            color: #f1c40f;
        }
    }

    mark {
        position: absolute;
        transform: translate(40px, 0);

        >span {
            display: block;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: rgb(29, 73, 155);
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 0.8em;
            padding: 2px;

            &.d {
                background-color: #a86a44;
            }
        }

    }

    .icon {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .unknown {
        svg {
            font-size: 4em;
        }

        margin-bottom: 13px;
    }

    span {
        display: block;
        padding: 4px 0;
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        font-size: .84em;

    }
}

.controllight {
    .icon {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}