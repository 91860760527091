@use "../../styles/config/colors" as *;

.container {

    .tag {
        display: inline-block;
        background-color: #fff;
        font-size: .7rem;
        padding: 4px;
        background-color: $blue-dark;
        color: #fff;
        border-radius: 5px;

        &.location {

            margin: 0 5px 0 0;
            background-color: #ad56ad;
        }

        &.points {
            background-color: #648f14;
        }
    }

    .list {
        height: calc(100vh - 140px);
        overflow: auto;
        margin: 10px 0 0;

        .fav {
            background-color: #fff;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            cursor: pointer;
            border-bottom: solid 1px #eee;

            &:hover {
                background-color: #e8f2f8;
            }

            &>svg {
                font-size: 1em;
            }

            a {
                text-decoration: none;
            }

            .h {
                display: flex;

                h3 {
                    margin-right: 10px;
                }

                margin-bottom: 10px;
            }

            .imgwrp {
                position: relative;

                display: inline-block;

                span {
                    top:5px;
                    left:5px;
                    display: block;
                    position: absolute;
                }

                img {
                    width: 150px;
                    height: 150px;
                    object-fit: cover;
                    border-radius: 6px;
                }

                &:hover{
                    span{
                        opacity: .2;
                    }
                }
            }

            .f {
                svg {
                    display: block;
                    color: $pink-dark;

                    margin-right: 5px;
                    cursor: pointer;
                    font-size: .88em;

                    &:hover {
                        filter: brightness(115%);
                    }
                }

            }
        }
    }

}


@media screen and (max-width: 600px) {
    .container {
        .list {
            height: calc(100vh - 104px);
        }
    }
}