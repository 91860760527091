@use './config/colors' as *;

 .wrapper {
        display: inline-block;
    }

.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        visibility: hidden;
        height: 0;
        width: 0;
    }
    .checkmark {
        height: 18px;
        width: 18px;
     
        border:solid 2px #555;
        padding: 3px 0 0 7px;
        border-radius: 5px;

        &.false-checked{
            background-repeat:no-repeat;
            background-position: center center;
        }

        &.checked {
            background-color: $blue-dark;
            border:none;
            pre {
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
    label {
        margin: 0 0 0 7px;
        cursor: inherit;
        color:#1E4586 !important;
        font-size: 1em;
        font-weight: normal;
    }

    &.sm{
        .checkmark {
            height: 16px;
            width: 16px;
            padding: 3px 0 0 6px;

            pre {
                width: 4px;
                height: 7px;
                border-width: 0 1px 1px 0;
            }
        }
        label {
            font-size: .88ems;
        }
    }

    &:hover{
        .checkmark{
            &.checked {
                background-color: #5898c3;
            }
        }
    }
}

.subcheck-options{
    display: flex;
    width: 40%;
    min-width: 250px;
    .arrow{
        position: relative;
        margin:0 5px 0 8px;
        border:solid 3px $blue-light;
        border-top:none;
        border-right:none;
        border-bottom-left-radius: 5px;
        height: 17px;
        width: 25px;
        &:after{
            position: absolute;
            content: ' ';
            top:11px;
            right:-1px;
         
            width: 9px;
            height: 9px;
            border: solid $blue-light;
            border-width: 0 2px 2px 0;
            transform: rotate(313deg);
        }
    }
}

.cbrow{
    margin: 10px 0;
}
 
 
@media screen and (max-width: 600px) {
     .cbrow{
        margin:4px 0;
     }
     div{
        &.wrapper{
            label{
                margin:0 0 0 5px !important;
                font-size: 0.82em;
            }
        }
    }
}