/* icons  color #35759F */
@use './config/sizes' as *;
 
.search-icon{  
    @include square(24px);
    background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABEElEQVRIie2TTWoCQRCFe9xGd+pJsvfnBkoOkixk4kJwdKOnMeg5YjyAnsGfCFmZLwvfQKM4VqshIHlQdM/MV/WKocq5uxRQBvrAB7BVTIEeULq2+BOw4bTWQPOa4t8qNAIqwIOiCrzp2w5ohBYve523MrhYzAoohhj0084N7FhsEmIwU1LFwNbETkMMPpWUN7AFsZtzbM7PM3fjXGQFfYOFzkdDXsrMQwwmOp8NBi86xwZ2L6CkJQKIM7i2mGXwVgNNLREaxRqQV9SBibfRnaDinklDS3RKS6DjPXcvMSkCCfCu8d3qnqS/5cA03MTQRHxgMvg3ucgkl5VoVRRFQ+fcq/fq6xZ1jwR0f2Wi/lQ/2mvMQ0czO9IAAAAASUVORK5CYII=');
}

.person-icon{
    @include square(32px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABe0lEQVRYhe2WTUrDUBSFz2vViTpXwQ1YR+5Ap7W0FHHWuaLgzCxACsU16B50AdUOHCqICuLP0BW0HSiinwNv8ClJIGlTEHrgEfruu/d84d6kkcYa6z8KqAIdoG/rHKiMyrxFvJp5m1fN6A3YBxZsBbYHsJ4nQMdMgohYYLGzPAF6ZjIfEZuzWDdNzUJGFhexVwxZ8gS4tGsjItb4c2b4AireEAYxQ1jODcAgmgmP4UGu5h5EGWgDXRvMdu53npeipjlWwJSkmqSqpBVJi/qe+hdJV5JOJZ04596HzCkBdeA5of+hnoDaMI0LwKFncAPsASVg2lbJ9m69cy0g63vmF0Bo/gpsJxUFisCO90i2BjWve+arKfLWPIhqVvMpr+dbGfJ3LfcRmMwCsOn1PHUvrR13VmMj7lxS4XCSj5xzn2kBnHMfko7tZ/o2AA9Gv5Q6+afGstW4z5Ic/vfPDAAwazVivxEmEvKvJeGc62cFcM71gAul/EYYa6yR6gvv7AE8NP/ltAAAAABJRU5ErkJggg==');
}
.ico-triangle-24 {
    @include square(24px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAY0lEQVRIiWNgGAWjYBQMfcCIT9LTM738PwNDB14T/v9v2LFjViMuaWZ8eu/cOXtUVdXkBwMDgws5hhO0AK8lRBhOlAVYLSHScKItgFmiombKyMDIeHDH9plEGT4KRsEooBMAAOx6LUujlnndAAAAAElFTkSuQmCC');
}
.ico-code-24{
    @include square(24px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABWUlEQVRIie2UP0/CUBTFf6+BGBfpwiQu4jfoR3AyTGiCMQ5usDERHJQNF9mYfJsLJpJoGFQWv4LfQFxkg6F10ph4HaRYK22xkDjo2e45953T2/cH/hEB5S0aq0c5UaKB5Zh2fQOKld5B12UMryyKk/jmAJJ5Q7SXSfgbAKq9Q0UMHGfrAqx4OSOgd25ITCJHXzIX/M4EM+7BF0w1gbZ0Uls6GVSHITJAWzrp2MO2Yw9aLufYg5ZjD9vThIQGuOYgOUHOxoLIKciGYw86zbXmQqwAn3lhv1fruFr1oXaDyCaw/ixPl2EhEb9I5KNJfdt0haEABEKPdGBA6a70mjLT28CVwHkjW8+72ujNuhC4XVRLW+X78kusCT5D1LXAznguJXuguqaZzoeZQ8A98IdoSxe8XMpM77pa1PrIgElG0xgHBKg+SGbGt+jRWxi+ouhv+Lm5FGdY/xfxDhBdipC0Z3+yAAAAAElFTkSuQmCC');
}

.ico-hw-drk:hover{
    filter: contrast(60%);
}
.ico-home-16 {
    @include square(16px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAABKUlEQVQ4jWNgoCXwrVxl71u5yh6fGiZcEt6Vy/3+M/xb+Z/h30rvyuV+JBngU7EimpGBcdY/hv/+jIwMXkwMTDO8K1YmYVPLiKG5ank2w3/Gyv+MDB5b2yKuQLyyTOM/A9NOBgaGiVvaI/pwGuBdsbyckZEx7f//v65bO6LvIcv5lq+S+8/4bxcjE+OGzW3hFWgG/Gf0qVzZw8DA4MD6+5fn+p64V9ic696wSojt579t/xgZzpqy3chtaGj4x2icNpNVSohv3n9Gxpgf/5gE9nSGfcSmGQZcylfxczD9+8D4//+SZ+8+JbGIiHCJ/P/P8J2BgYEBWbNP5Yr/yBq3tEcwwtT4VK5g+M/I8F1EhEuEAZcGZD4+OZzpgFgw8AawIHPQ/Uqs3MACAE8lcVR6MiG9AAAAAElFTkSuQmCC');
}
.ico-search-16{
    @include square(16px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAABNklEQVQ4jc2SPSyDURSGn3O/auIrA6OkM0aqg8RgkJi6kjYGOtglSifaTkITs4So+Bkkpq5IN4mOBiQmNrFJ66ftPQaRVPL1I7o4433yvDnvyYU2R/xgJLPlmmowjZIAwsAD6GGg3rV2sTn1AmB85UrwDJUhLPE3dXqxxMEM153K6ejCcSdAoFWAqQbTIE/ljdlY03MZiEVThWLNqSwB2ZYboCREGllPZjVnkBnfCkBfB9x6gaBjb1Q0/FPAXQ36vcB7wwwA9/4BInuqzqonM7ICcgB+R1SuLJqPpgpFrOaq3dVr99kd/JS1JxRiveUGI4u74xbdN8gkQkkN227FfRSRI6AcCjFRysy9gsdHiizvjBlrTkQ1fplPnres+NW0HflbwF9kaDqi0zDzFqbL+WTpt/L/mA96fnAccEiOOwAAAABJRU5ErkJggg==');
}
.ico-looking-16{
    @include square(16px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAACEUlEQVQ4jcWSP0jUYRjHP8/v/Z2W12G56KYNSVtE3snRIE6ODZFQOXgVDoXBoVIgwWER1gVXQkNinUg1tDSGkEV/4EwdaqyGbswGS/yLvu/7NHieh0MEDX2nL9+X9+Hzfp8X/lFSdplM0LLceE2Ei6Wj+7PR4g0yGf+nAcG2ia803UK0zXqXsN4lQNsTy43Df0VwrC9/OAjkXbWJNL8fPvsTIJkeq7Nh+FmcHCeQTS/2oSCtwLQxnJ8eThXLBEY4B/pk+zJAIXdhAfFPNXSXVNykqLy2oTaBvHGeyWT62V6AEEBF2vH07cZTL89FmFLhxVw2db0UDyUG8q0uXD0FTJQ60EM+DIq7Bzh137YoZbQy9yIPFO0qE4BEF1d/zQPE+8dvInpZ4N7C+tLQgT0xnLOfKvNNZ0cjgWmu2IKu1Nbsry+12gtEFXpjVTUNAEsbq98r89qYmVeorxggXwPrm7asjojKsoqOGDEHAWJVNQ2V+eKSqxeY3ykR/0oMJ4G3M9nUIDAI0DKQHwEwJjwyc7t7J+9/dELhS5lAveTR4EwyPVa3XVQyPVYnyGmQO6A9lSUGIj2CPC49eUvxgfEsaNwYur01ouLyin6I2H0ZG1n5KF4m1gnuVotNg3SFNnq0kOtcK3/l2WjxCsqUc0yruALoy7ls6moh17lmAjpUtL1a3A8I2qx3HYVc59rutf8f/Qa4a9gKi3l4wgAAAABJRU5ErkJggg==');
}
.ico-location-16{
    @include square(16px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAABe0lEQVQ4jaWSwWoTYRSFvzPpxEJcuO0qr2A1KbgQZi24sE+QWtdVk4mIqy5EaFolqLgqTvAFiuBSSsAKkpJSH8E36MKpYpI5bowmY6IWz+7ee875z/258J/QrGY1Tq4j6kAVwLgXwJPe9s03eW7wu7jzCNGy3S4qWyoqWwqkp0Y7lXsvH/4xwY+XW4MBV47bayeTs4t3kgthyAfheDLJdAJRt30/LwY4bq+dIB4Y3Z27gqzKucD7E4ner8Sddz/JX8K343+ZaWD59Ou34vlfgZRZ9rgeFlwCTic1C9MruBsUhzeAFwC9ndrVqXE4WgW6cxNk8jNMI9pMFskh2kwWhetZkD2fa9DfWj+QdZSmjvMG6WeaQL+/tX4w1wBgVAgaENxebuyWx73lxm4ZaWOYjZp5/sxLXImTusVqqfQpAkjTchdp77BVe/xPBmBVm53XoI9WJlmXD7dr10DOMxdmyUEO/erWQKMjWYQuXJol/isqcRJV4iQ6s/As+A43o4nOwzzEFwAAAABJRU5ErkJggg==');
}
.ico-manage-16{
    @include square(16px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAABbElEQVQ4jcWSvy8DcRjGP+/dpeLaYBX+Aj8SCZeyMYjJakMkGiZB1EiRGEpioIMY6sfmP9CFrWlEIk3qL6jVoneC670GIdSR2zzjk+f7yfN93xf+WxJmdmfOY81ubUtEJlEU5cxLxNcqmYmXxqwRBrBr7iYi3dar4RjiJ0XotV13IywbCkCYNPHni3vT96Vsqqp1nQemogPCpWGm9Uv4VNU6TK4ezQW+KVgcEshp5AZePL6uUA7UKmFKkcC49RJ2JjLArrljwCDQCtiI9rR5j02RAM7K8TZCTpGDmATtQBemLgavVguAk84rwNDSSQc03IGzkh9HyFl+va+4N/vw4SdXjzoDtW4UrQgyAlwBXYb4/d8bCMuKLH19DFDKpqrAnSFy8axmAtECcFfKpqrfAKIy0CT1wo9vvdcedm0vV96dcmPoATDspPMaesphctL5S0QLnv203+zaCwKj1zszI5EAf80gaoHPqTdu4Q2tbozA99Hh0AAAAABJRU5ErkJggg==');
}
.ico-wrench-16{
    @include square(16px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAABl0lEQVQ4jc2SQWsTURCAv3n7YhODEMQuWqR/wlMvTXOQ4kkQQlKKbAMFT2qxB/+DOeTmRUvYICVBLx7qQSuGQkH7F7yJYElFsUi7q/t2PJgtCCYpeHFuw8z3MTMM/GPIuOLy+SeXnXUtgUWF78Dr/cFso08lyXrMBHhPYQ/RW0AOk270qSQLvLETBc66lkKrNwiaqvIIk1a7+43+0oVO+aL/oZ312VECgUWbmjvDtJjBavQZMHkFhTjOx3GW1/1Q1egWIndBCmMnWCm1SxFy6EV2DtjqDoKTY9enw/sqsj1SsFJql6Kc9xJ43v0cvKhNy7V06uc7L5I8Ym8irHnq5v8qyGAl3e0NgvWaHzYFueH9yJ3DcEaRV566+c2Dxvs/BNWZzqyXpI8jpKyaPuwdBPdqftgUkQUT2Sub35a/jrqVBbCJbqjKtpsqXH/6sRrV/c4DfsNXx8Ew/MQlPzxMtDBjzHHvyIuqxST/xcT20iT4ZAJF31qObifp2VoR1kB3TgPD8A+cNasqVDw5/oRSdtasngb+P+IX+bClurs0xLEAAAAASUVORK5CYII=');
}

.ico-warn-16{
    @include square(16px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAABbklEQVQ4jcWSsUtCURTGf+f6ICG1Iakme4Lg4BZFgRk0VnP9E0ZDi/9CbQ0vXIPWpoacQjAjwdcoFARlm9VmYZC90xCJ7ykhNHSmez+++/vO4Vz4Y0lQuE6S85S8QA6IA88CF56Hs/DIZdBvfg5lsFyboipHRjjvWiwySaRrsYRQFsNxPYlTBmtoB65NUWA2PM5WpsFrMKmRIdJ540SFu4V7tgfadm3uGhki/bpro/33apqoa3NfT5D1jeApeRH2hyX31/ItbYU9MeR9AIHcR4jSb497MxtKwIoPAMSjYzyNApgwtPjejg/w8vbO9CiA9iczKM8+gEAl5LEWNM8/DP4TT1lHqPgAajhUpVBNE+03B7dQSxEDCkZxfrReQj2JI0rqfYzN5VvaweRaipjV5QTlZr7JzgCgDFYkyYEoGwp7GuJsPEyr02EGjw2ggHLabrK7Ct0BQK+TBFkx5FFyCFMoTwgVozhzTa6C/v+vL6yodLGH6OW5AAAAAElFTkSuQmCC');
}
.ico-photo-32{
    @include square(32px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAB1ElEQVRYhc2Xv2pUQRSHvxNsLFQUschiI0gEV1MJNhF78wIWWppHWFsfQARfQyGNL2BhZaOghY2NmJDgH9hCMOB+KZwN1+vM3b25e1cPXLh75nfm982ZGbgL/1OoF9Xn6tjFx1jdVteqnlE1B94A53pe53dgPSI+AaxUBp4swRzgLPB4+qPagTFwagkAAOOIOFMHcEnmAEREwJ9b8E9iEQAHwAgYpOdhyrWLDtdrlJlrNKtoql3EGRhExI76CphExIa6CnxuKurjDEyA1otYBMA9gIjYiIhbKXe/9SwdzsDPtOer6RmlXC9nYB/YTO8vgAutVzo1TmegTQf21GFFP0y5knZTfaROmjowL8CReTIeNkDUQbfUX10A6uZ7hVxde1k9md7vqgfHAdhXr6XxK+puw9jbjPalejrl7qg/2gCUVlnSrBS0r9Xzaey26WNnFsA85lkI9V1G814dJM0N9UsTQBvzNjUf1UtJc7UE0LTns6Jeu5PR7KrX/zJPRV3M20B8U2/mANq2vRTzbMe41IXjrrweMzvRp/lcEDmALm0vRXE7cgB9/BsqQXzNAWz3BJCDeJoDWPP3FekdohpHn2QR8QFYB54B+WvSLU4AD3qYt1scAvYCeVMsjRFhAAAAAElFTkSuQmCC');
}
.ico-add-24{
    @include square(24px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABu0lEQVRIidWVvW4TQRSFv2sgsi3RI0SDSYHkLjPeJuEZHIsqrxEiLOUFovw8iYVgzSsYpfHE3VIF0oB4hF0RaS8FXmt/7J1NUuVUM3fuPefMj+7AY4fULUZRtJUkyUhVh8AO8Gq59AtYiEjYbrfDfr//984C8/n8vYicAT2PyR8i8tEY87mRwGQyedLr9U6BDx7iMi6MMWMRSfPBVjnrnuQAR4vF4qQcLOxgeSyf7kGex8haG1YEoijaiuP4O/DmgQI3nU7nbXbxqyNKkmTkI0/TdBd45xF4HcfxMJvk72DfZy0Igktr7TdfHlAVUFXToLApbDZ4mgu+WJeZpuluEASXhWprBcA5twfM1pS9zAaVZ1pGq9XamCMitZ0Aijv4AzxfkzNzzgEF5wqgqpt4f68M5oJXPjdNoaouG692ICKhqh7UFTrn9kREapxnmFYEVPULcA1s1xTOGpD/7Ha7X7PJ6oistbciMvZV+6Cqh/n2XXghy5Z78QD+08FgMM0HKk/QGDNW1fO7MovImTHmuBLfVOCc2wfOqb8TgGtVPSo79wosRZ7xv0cNAUPxy7xS1VBEptbaW4+JR4x/QK6QoRWIkyIAAAAASUVORK5CYII=')
}
.ico-bell-24{
    @include square(32px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABOklEQVRYhe2XsUrEQBCGZxSiiKWdT6CQRhDkrrRRfCULm0MLQYUTfATxHax9A3s95a7RSlSQy2fhHKYwye0yl0PYv8kW//zzZZfdbESS/quAA+AJGAD78wAY8KvHNhvnwAVQlAAK4AzYmGXjJaAPjKnWF3ACLHo3XwZurckHcApsARmwAuwAVwYAcAMseAJcWvADsFnj6wIv5j30ap7bGr8D+RT+XVumT2DdA6Bvb3QeUHNtNUceAPcWth1Qs2c1dx4Abxa2GlCzZjWvTV6dIgwREVVt9JZqVEQKEUFVa3eD31YpSVWZDJu8MwEIUQJIAAmgFgDo2nAUkT2yjE6dqfKgmJyAXqo6See+BFEChvax6dglBGAYkxU7A4U9y9M6jswKF3D8x4W01yZAZhDP/Pyc9ICsNYAkT30DuKBvYMn/Ki4AAAAASUVORK5CYII=')
}
.ico-gears-16{
    @include square(16px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAB2klEQVQ4jaWSMWtTcRTFf/efF9Ek6iqoaHFwKIKFJMUP0EXoWHGpzwSFDkJVTCA2aNQ0lZeh6laUtKmLmk2kix8gNHnQ4qSDVMGCszYRzMu7Dn2WRhsI9Iz3nns4994D+4T0ayTuLJUQzQWkUqOcmhlIYGLiTejLUPsyqkUVmQYQ1aeI5E9vRF7Vape6ewokM5VrKuYoquPAL/XNs9jhjfcAWz+HxkT8aYSDorxF9EejnH6xI3DhVvW4Z+m64q+I8LHppB6DaK83lXhmKQd6VjAXLU/O1+ftTQvAs/wcok3XSdsAw4XYgUOtyiMRmURRlJftWO2eW0iVABLZyopnSQ64YQL5Iioj8Ww1CRDZaj1EZNjqmIQRb1SEc5FW6wFAPFtNojICFAEMQLOc+g6ETYjPwWUmQ3hT9Xl7c9W5/k27OgVcAQg44WCGvw4Gge5VNACJzOIxoON3ORPUl1WthdHs8xOJ25WTWCygsgwQcDrBzI6DPKJrrmM3ANrR6H2FD75aq4Skjm/W27FIAcB17Aaia0B+e1t63wjyyS1fnRv0jT1BQuWICuMKv/HNk91Bwvg3QcNG5d1/QdqNflEWZebU1+jrvlH+F8nM4qzC3W3zOus66Xw/7r7wBzsO1ov5LOLKAAAAAElFTkSuQmCC');
}
.ico-bolt-16{
    @include square(16px);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAABP0lEQVQ4jaWSvUpDQRCFv1msLCyskotIsp2NIIqFoK2FMSAayAvYWFsoYrJcC99DENTCwidIb6GQxsZrGu8Fn8Ai2bHQSH7uRtTT7eyZjzO7A/+UhC7Spj1HORqu6kMxflkW0H7FBMnKnjd+KYoTieJEgHuMiQebg4DU2RUFP+c6jwBZw24B00We70a9uQBRaojefAeHYzHqxOFHvVN5AFV2jcoOQNoobYLMFkhu87xjCTJXXgW6hbOk/WU5AV3IvO2lDatp015OBHg1NYWr/jmKk40oTkTgAGgj7/tD4w5FB8kaNgFKA+WeINuKXmD8euQ6T0HAqF5duSpeYmAGw2Hkxt8huAcA4qUKLCJc5zVPBKjDABWgVZT505AvCMiwa0DXGKmLa3V/DRBPBUO94J7fQp6J+lygP0p/+J1BfQACkmVryxhB7AAAAABJRU5ErkJggg==');
}