.container {
    

    &.inline{
        display: flex;

        >* {
            margin-right: 15px;
            &:last-child{
                margin-right: unset;
            }
        } 
    }

    &.required {
        padding: 4px 0 0 10px;
        border-left: solid 3px #b52d5f; 
    }
}
