.page {
    padding: 10px;

    .controls {
        display: flex;
        margin-bottom: 1rem;

        >* {
            width: 250px;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }

        .selector {
            .searchfield {
                width: inherit;
                padding: 9px 6px;
                color: #555;
                border: solid 1px #aaa;
                border-radius: 5px;
                font-size: 1em;
            }
        }
        .invitebtn {
            padding: 10px 12px;
    
            span {
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
}

@media (max-width: 1300px) {
    .page {
        .controls {
            >* {
                width: 220px;
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 1150px) {
    .page {
        .controls {
            .selector {
                width: 160px !important;
            }
        }
    }
}