@use './config/colors' as *;

.empty-frame{
    cursor: pointer;
    border: dashed 3px #555;
    border-radius: 3px;
    width: 80px;
    height: 120px;
    background: rgb(161,161,161);
    background: -moz-linear-gradient(top,  rgba(161,161,161,1) 0%, rgba(143,143,143,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(161,161,161,1) 0%,rgba(143,143,143,1) 100%);
    background: linear-gradient(to bottom,  rgba(161,161,161,1) 0%,rgba(143,143,143,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a1a1a1', endColorstr='#8f8f8f',GradientType=0 );
    display: flex;
    flex-direction: column; 

    div{
        place-self: center;
    }
    .ps{
        place-self: end;
        margin:3px 3px 10px 0;
    }
    &:hover{
        filter:brightness(90%);
        div.ps{
            filter:brightness(200%);
        }
    }
    &.warning{
        border-color:$red-validation;

        background: rgb(239,227,230);
        background: -moz-linear-gradient(left,  rgba(239,227,230,1) 0%, rgba(237,225,228,1) 44%, rgba(236,222,225,1) 100%);
        background: -webkit-linear-gradient(left,  rgba(239,227,230,1) 0%,rgba(237,225,228,1) 44%,rgba(236,222,225,1) 100%);
        background: linear-gradient(to right,  rgba(239,227,230,1) 0%,rgba(237,225,228,1) 44%,rgba(236,222,225,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efe3e6', endColorstr='#ecdee1',GradientType=1 );
        

    }
}