@use "../../styles/config/colors" as *;


.container {

    width: 100%;

    table {
        width: 100%;

        .onlywidecell {
            display: table-cell;
        }

        .onlymobilecell {
            display: none;
        }

        th {
            text-align: left;
            padding: 4px;
            border-bottom: solid 1px $blue-extra-light;
        }

        tbody {
            td {
                padding: 12px 4px;
                font-size: .9em;
                border-bottom: solid 1px #ccc;


            }

            tr:nth-child(even) {
                td {
                    background-color: #f8f8f8;
                }
            }

            tr {
                &:hover {
                    td {
                        background-color: #d7e5ef;
                    }
                }
            }
        }
    }
    .onlymobile {
        display: none;
    }
    .cancellationreason {
        margin: 5px 0 0 0px;
        font-size: .8em;
        color: #104488;
        text-decoration: underline;
        cursor: pointer;
    }
    .legend {
        flex-wrap: wrap;
        align-items: start;

        span {
            width: 30%;
            min-width: 60px;
            font-size: .8em;
            margin: 0 5px 5px 0;

            &.state {
                align-items: start;
                flex-shrink: 0;
            }
        }

        margin: 0 0 10px;
        background-color: #f0f0f0;
        padding:5px;
        border-radius: 5px;

        span {
            &.state {
                display: flex;
                align-items: center;

                &::before {
                    content: ' ';
                    display: block;
                    margin: 0 5px 0 0;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background-color: #eee;
                }

                &.s_new {
                    &::before {
                        background-color: $blue-light;
                    }
                }

                &.s_open {
                    &::before {
                        background-color: $dark-yellow;
                    }
                }

                &.s_redeem {
                    &::before {
                        background-color: $btn-green;
                    }
                }

                &.s_cancel {
                    &::before {
                        background-color: $pink-dark-border;
                    }
                }

                &.s_deletedaccount {
                    &::before {
                        background-color: #000;
                    }
                }
            }
        }
    }

    span {
        &.state {
            display: flex;
            align-items: center;

            &::before {
                content: ' ';
                display: block;
                margin: 0 5px 0 0;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background-color: #eee;
            }

            &.s_new {
                &::before {
                    background-color: $blue-light;
                }
            }

            &.s_open {
                &::before {
                    background-color: $dark-yellow;
                }
            }

            &.s_redeem {
                &::before {
                    background-color: $btn-green;
                }
            }

            &.s_cancel {
                &::before {
                    background-color: $pink-dark-border;
                }
            }

            &.s_deletedaccount {
                &::before {
                    background-color: #000;
                }
            }
        }
    }
    code {
        background-color: #d8d8d8;
        padding: 3px 6px;
        border-radius: 3px;
        color: #555;
        text-shadow: 1px 1px 1px #fff;

    }

}

@media screen and (max-width: 740px) {
    .container {
        .onlywide {
            display: none;
        }

        .onlymobile {
            display: flex;
        }
    }
}