.component {

    $width: 215px;

    width: $width;

    position: relative;

    h2 {
        font-weight: normal;
        background-color: #2e569b;
        color: #fff;
        //ellipsis
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.1em;
        line-height: normal;
        padding: 5px;
        text-align: center;
    }

    section {
        display: flex;
        position: absolute;
        padding: 4px;

        .tag {

            display: block;
            padding: 2px 4px;
            border-radius: 3px;

            font-size: .84em;

            &.points {
                background-color: #2e569b;
                color: #fff;
            }

            &.onlypoints {
                background-color: #648f14
            }
        }
    }

    .thumb {
        margin: 0 10px 0 0;
        width: $width;
        height: $width;

        img {
            width: $width;
            height: $width;
            object-fit: cover;
        }

    }

    &.demande {
        h2 {
            background-color: #a86a44;
        }
    }
}

@media (max-width: 980px) {
    .component {

        $width: 150px;
        width: $width;

        h2 {
            font-size: .9em;
        }
 
        .thumb {
            margin: 0 10px 0 0;
            width: $width;
            height: $width;

            img {
                width: $width;
                height: $width;

            }
        }
    }
}
@media (max-width: 900px) {
    .component {

        $width: 120px;
        width: $width;

        h2 {
            font-size: .9em;
        }
 
        .thumb {
            margin: 0 10px 0 0;
            width: $width;
            height: $width;

            img {
                width: $width;
                height: $width;

            }
        }
    }
}
@media (max-width: 600px) {
    .component {

        $width: 115px;
        width: $width;

        h2 {
            font-size: .8em;
        }
 
        .thumb {
            margin: 0 10px 0 0;
            width: $width;
            height: $width;

            img {
                width: $width;
                height: $width;
            }
        }
    }
}