.text-tiny{
    font-size: .78em;
}
.text-small{
    font-size: .92em;
}
.text-big{
    font-size: 1.3em;
}
.text-huge{
    font-size: 2em;
}
