.control {

    .row {
        margin: 10px 0;
    }

    .footer{
        transform: translate(0,50px);
        display: flex;
        justify-content: flex-end;
    }
}