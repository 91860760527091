.control {

    overflow: auto;
    max-height: inherit;

    .group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #e0e0e0;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background-color: #f5f5f5;
        }

        .group-name {
            font-size: 1.2rem;
            font-weight: 500;
        }

        .group-count {
            font-size: 1rem;
            color: #757575;
        }
    }
}