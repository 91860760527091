.container {
    padding: 20px;

    h3 {

        font-size: 1.8rem;
        color: #1E4586;
        font-weight: normal;
    }

    p, a{
        margin:20px 0 0 0;
    }
    .controls {
        display: flex;
        align-items: center;
        margin: 20px 0 0 0;

        span {
            margin: 0 0 0 10px;
        }
    }
}