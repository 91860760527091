.menu-container {
    height: inherit;
    background: #fff;

    .link-item-container {
        border-bottom: solid 1px #b4b4b4;

        .link-item {
            position: relative;

            .f00 {
                display: flex;
                align-items: center;
                padding: 8px 6px;

                .icon {
                    margin-right: 5px;
                    flex-shrink: 0;

                    &.c {
                        -webkit-transform: rotate(270deg);
                        -moz-transform: rotate(270deg);
                        -o-transform: rotate(270deg);
                        -ms-transform: rotate(270deg);
                        transform: rotate(270deg);
                    }
                }

                .alight-right {
                    margin-left: auto;
                }

                .has-notification {
                    display: block;
                    height: 6px;
                    width: 6px;
                    background-color: #ff7b00;
                    border-radius: 50%;
                    flex-shrink: 0;
                    transform: translate(-3px,0);
                }
            }
        }

        span,
        a {
            color: #393a52;
            cursor: pointer;
            text-decoration: none;
            user-select: none;
            display: block;
            width: 100%;

            &.active {
                &::after {
                    content: ' ';
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    background-color: #1E4585;
                }
            }
        }

        ul {
            li {
                padding: 0 0 0 23px;
                font-size: .84em;
            }
        }

        div {
            &.link-items {

                >a,
                >span {
                    display: block;
                    padding: 8px 6px;
                }
            }
        }
    }

    .link-collection {
        &.collapsed {
            display: none;
        }
    }
}

.menu-container .link-item-container .link-item .f00:hover,
.menu-container .link-item-container>div.link-items .link-item:hover>a,
.menu-container .link-item-container>div.link-items .link-item:hover>span,
.menu-container .link-item-container>div.link-item:hover>a,
.menu-container .link-item-container>div.link-item:hover>span,
.menu-container .link-item-container ul li:hover {
    background-color: #c8c8d1;
}