@use "../../styles/config/colors" as *;

.results {
    .nothing {
        margin: 10px 0 0 0;
    }
}

.result {

    display: flex;

    min-height: 100px;
    border-bottom: solid 1px #eee;
    padding: 8px;
    background-color: $blue-extra-light;
    margin: 0 0 10px;
    font-size: .82em;

    &:last-child {
        margin-bottom: 0;
    }

    &.demande {
        background-color: $orange-extra-light;
    }

    &:hover {
        filter: brightness(95%);
    }


    .thumb {
        margin: 0 10px 0 0;
        width: 150px;
        height: 150px;

        img {
            width: 150px;
            height: 150px;
            object-fit: cover;

            border-radius: 6px;
        }

    }

    .exchange {
        color: $blue-dark;

        h5 {
            margin: 0 0 5px;
            font-size: 1.3em;

        }

        strong {
            display: inline-block;
            margin-right: 4px;
            color: #555;
        }

        .tag {
            display: inline-block;
            background-color: #fff;
            font-size: .7rem;
            padding: 4px;
            background-color: $blue-dark;
            color: #fff;
            border-radius: 5px;

            &.location {

                margin: 0 5px 0 0;
                background-color: #ad56ad;
            }

            &.points {
                background-color: #648f14;
            }

            &.p100 {
                background: linear-gradient(to bottom, #fcd04b 0%, #febf01 100%);
                font-size: .8rem;
                color: #4f632d;
            }
        }

        a {
            display: inline-block;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .b {
            padding: 3px 0;
        }

        .f {
            display: flex;
            align-items: center;

            &>* {
                margin-right: 4px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }



}