@use "../../styles/config/colors" as *;
@import '../../styles/mixins';

.popup {

    position: absolute;


    left: 50%;
    transform: translate(-50%, -70px);
    height: calc(100vh + 15px);
    z-index: 200;
    width: 100%;
    background-color: rgba(100, 100, 100, .3);

    .content {
        border-radius: 5px;
        margin: 70px auto 0;
        max-width: 480px;

        background-color: #fff;
        border: solid 1px #ccc;

        min-height: 200px;

        @include shadow-active-2pd;

        header {
            padding:5px;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;

            h4 {
                font-weight: normal;
                color: $blue-dark;
            }

            button {
                background: none;
                border: none;
                cursor: pointer;
                color: $blue-dark;
            }

            border-bottom: solid 1px #eee;
        }

        .body {
            padding: 5px;
        }
    }

}