.page {
    padding: 20px 0 0 0;

    h1 {
        font-size: 1.4em;
        font-weight: normal;
        color: #1E4586;
    }

    .form {
        width: 800px;

        .row {

            &:last-child {
                margin-bottom: 0;
            }

        }
    }

    .createbtn{
        display: inline-flex;
        align-items: center;
        padding:2px 6px 2px 2px;
        svg{
            margin:0 3px 0 0;
        }

    }

    .controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .maxheight {
        max-height: calc(100vh - 400px);
        overflow-y: auto;
        margin-bottom: 20px;
    }

    .messages {
        margin-top: 20px;
        height: calc(100vh - 230px);
        overflow-y: auto;
    }

    .message {
        display: flex;
        align-items: center;
        padding: 10px 0;
        margin-bottom: 20px;
        cursor: pointer;
        &:hover{
            background-color: #e9f0fc;
        }
        &:last-child{
            margin-bottom: 0;
        }
        

        .mc {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

          

            h2 {
                color: #1E4586;
                font-family: 'open sans';
                font-weight: normal;
                font-size: 2em;
                margin: 0;
            }

            .op {
                display: flex;
                align-items: center;
                margin-top: 6px;
                font-size: .9em;
                
                .published {
                    display: flex;
                    align-items: center;
                    background-color: #298648;
                    color: #fff;
                    border-radius: 6px;
                    padding: 2px 4px;
                    svg {
                       font-size: 1.1em;
                       margin-right: 3px;
                    }
                    span{
                        font-size: .8em;
                    }
                    &.not{
                        background-color: #e77326;
                    }
                }

                .p {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;

                    strong{
                        font-weight: normal;
                        color:#1E4586;
                        margin-right: 3px;
                    }

                    span {
                        margin-right: 3px;
                    }
                }
            }

        }


    }
}