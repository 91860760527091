@use "../../../styles/config/colors" as *;

.container {
    .subheading {
        margin: 10px 0 20px 0;
    }

    .form {
        max-width: 600px;
    }

    .list {
        height: calc(100vh - 123px);
        overflow: auto;
    }

    .category {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &>a {
            display: block;
            text-decoration: none;
            width: 100%;
        }

        &:hover {
            background-color: #e8f2f8;
        }

        h3 {
            font-size: 1.4em;
            color: $blue-dark;
            font-weight: normal;
        }

        padding: 10px;
        border-bottom: solid 1px #eee;

        .description {
            margin: 5px 0 4px 0;
            font-size: .82em;
        }

        .example {
            color: #777;
            font-size: .82em;
        }
    }
}
@media screen and (max-width: 600px) {
    .container {
        .list {
            height: calc(100vh - 150px);
        }
    }
}