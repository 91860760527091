@use './config/colors' as *;

.container {
    display: none;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 2000;     
    width: 100%;
    background-color: #f0f0f0;
    opacity: 1;
    transform: translate(-101vw, 0);
    transition: all ease-out .15s;
    height: 100vh;
    overflow: hidden;

    &.show {
        transform: translate(0, 0);
    }

    .heading {
        display: flex;
        justify-content: space-between;
        padding: 4px;
        margin-bottom: 10px;

        img {
            margin: 5px 0 0 5px;
        }

        button {
            background-color: $blue-dark;
            border: none;
            display: block;
            height: 22px;
            width: 24px;
            border-radius: 3px;

            svg {
                color: #fff;
                font-size: 1.2em;
            }
        }
    }

    .menu {
        height: calc(100vh - 40px);
        overflow: auto;

        .primary {

            margin-bottom: 14px;

            &:last-child {
                margin-bottom: unset;
            }

            a,
            span {
                color:$blue-dark;
                font-weight: normal;
                line-height: normal;
                display: block;
                padding-left: 3px;
                text-decoration: none;
            }

            .toplink {
                width: 100%;
                display: flex;
                margin: 0;
                font-size: 1em;
                background-color: #4a6a9e;
                padding: 8px 6px;
                color: #fff;
                border: none;

                a{
                    color:#fff;
                }

                svg {
                    margin: 0 8px 0 0;
                }
            }

        }

        .subone {
            margin: 10px 0 0 0;

            a {
                margin: 0 0 6px 10px;
                font-size: 1em;
                display: block;
                background-color: #4a6a9e;
                padding: 10px 6px;
                color: #fff;
                border: none;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;

                svg {
                    margin: 0 8px 0 0;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .container {
        display: block;
    }
}