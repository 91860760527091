@use "../../styles/config/colors" as *;

.container {
    margin: 20px 0 0;

    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 200px);
    overflow: auto;

    justify-content: start;
    align-items: start;

    fieldset {
        margin: 0 10px 10px 0;
        border: solid 1px #ccc;
        padding: 10px;
        border-radius: 6px;
        font-size: .8em;
        display: block;
        min-height: 400px;
        min-width: 200px;
        max-width: 400px;

        legend {
            font-size: 1.2em;
            color: $blue-pen;
        }

        .error {
            color: $pink-dark;
        }

        .warning {
            color: $orange;
        }

        .sub {
            strong {
                margin-bottom: 3px;
            }

            span {
                margin-left: 5px;
            }

            >div {
                margin-bottom: 5px;
            }


        }

        .dr {
            margin: 0 0 6px;
            display: flex;

            strong {
                display: inline-block;
                width: 150px;
                flex-shrink: 0;
            }

            span {
                width: 250px;
                display: inline-block;
                word-wrap: break-word;
            }

        }

        .limiter {
            height: 345px;
            overflow: auto;

            &.togglerowcolor {
                >div {
                    overflow: auto;
                    word-wrap: break-word;
                    &:nth-child(odd) {
                        background-color: #f0f0f0;
                    }
                }
            }
        }


    }

    .history {
        margin: 0 0 20px;
        padding: 10px;
    }
}