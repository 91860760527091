$white: #ffffff;
$black: #000000;
 
$blue-light:#4b89b3; 


$blue-very-dark: #173446;
$blue-pen:#1f2cd4;
$red-validation: #cc2000;
$red-validation-background: #ffe5e0;
$pink-dark: #D81A59;
$pink-dark-border: #ad1547;
$text-input-yellow: #FFFEEE;
$notice-light-green:#D9F2D5;
$notice-light-yellow:#f8e7cd;
$notice-light-red:#f4d2cc;
$btn-green:#72a058;
$btn-green-border:#496638;
$dark-yellow: #FFD325;
 
$orange: #D64408;

$blue: #9CBEF3;
$blue-dark: #1E4586;
$blue-extra-light:#e4ebf7;
$orange-extra-light:#f4eae4;