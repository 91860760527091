@use './config/colors' as *;

input[type="submit"],
a,
button {
    &.btn {

        display: inline-block;
        background: none;
        border: solid 1px #393a52;
        color: #484967;
        cursor: pointer;
        outline: none;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        padding: 5px 7px;
        cursor: pointer;
        background-color: #f0f0f0;
        user-select: none;
        font-size: .9em;

        svg{
            margin-left: 6px;

            &.before{
                margin-left:unset;
                margin-right: 6px;
            }
        }
        &.sm{
            padding: 3px 5px;
            font-size: .8em;
            svg{
                margin:0;
            }
        }

        >* {
            color: inherit;
            margin: 0;
            padding: 0;
            cursor: pointer;
        }

        &.action {
            border-color: $blue-dark;
            background-color: $blue-dark;
            color: $white;
        }
        &.engage {
            border-color: #E4AD4A;
            background-color: #E4AD4A;
            color: $white;
            text-shadow: 1px 1px 1px #777;
            font-size: 1.3em;
        }
        &.signup {
            border-color: #E4AD4A;
            background-color: #dfa12f;
            color: $white;
            text-shadow: 1px 1px 1px #777;
        }

        &.save {
            border-color: $btn-green;
            background-color: $btn-green;
            color: $white;
        }

        &.save2 {
            background-color: #139378 !important;
            border-color: #0E7059 !important;
            color: $white;
        }

        &.warn{
            border-color: #8E5329;
            background-color: #AF6433;
            svg{
                color:$white;
            }
            color: $white;
        }
        &.cancel {
            border-color: #cf457c;
            background-color: #cf457c;
            color: $white;
        }

        &:hover {
            filter: brightness(115%);
        }

        &:active {
            box-shadow: 1px 1px 1px #aaa;
            filter: brightness(95%);
        }

        &:disabled {
            cursor: not-allowed;

            * {
                cursor: not-allowed;
            }

            background-color: #aaa !important;
        }
    }
}

a {
    &.btn {
        display: inline-block;
        text-decoration: none;
    }
}

button {
    &.btn div {
        cursor: inherit;
    }

    &.btn label {
        cursor: inherit;
    }
}






.btn-ico {
    height: 32px;
    width: 32px;
    border: none;
    cursor: pointer;
    cursor: pointer;
    user-select: none;
    outline: none;

    &:hover {
        opacity: .8;
    }

    &:active {
        opacity: .6;
    }
}

/* icon buttons */
button.btn-form-ico {
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    outline: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
}

button.btn-form-ico>span {
    display: block;
    flex-shrink: 0;
}

button.btn-form-ico:hover {
    opacity: .84;
}

button.btn-form-ico:active {
    opacity: .92;
}



.button.start {
    user-select: none;
    cursor: pointer;
    padding: 20px 40px;
    border-radius: 8px;
    background-color: #74759e;
    border: solid 1px #484967;
    color: #fff;
    font-size: 1.2em;

    &:hover,
    :active {
        background-color: #a2a3be;
    }
}

a.btn.post {

    svg {
        display: none;
    }

    padding: 8px 14px;
    margin-right: 10px;
    background-color: #ad6e39;
    color: #ffffff;
    border-color: #82a4ba;

    &:hover {
        filter: brightness(110%);
    }

    &:active {
        filter: brightness(90%);
    }
}


@media screen and (max-width: 600px) {





    a.btn.post {

        span {
            display: none;
        }

        svg {
            display: unset;
            margin:0;
        }

        font-size: .8em;
        padding: 6px 8px;
        margin-right: 0;
        background-color: #ad6e39;
        color: #ffffff;
        border-color: #82a4ba;
        line-height: unset;
        display: block;
        align-self: center;

        &:hover {
            filter: brightness(110%);
        }

        &:active {
            filter: brightness(90%);
        }

    }
}