@use '../../styles/config/colors' as *;
@use '../../styles/config/shadows' as *;

.container {

    .list {
        margin-top: 10px;

        overflow: auto;
        height: calc(100vh - 140px);
    }

    .f {
        display: flex;

        a {
            display: block;
            margin: 0 0 0 20px;
        }
    }

    .tag {
        display: inline-block;
        background-color: #fff;
        font-size: .7rem;
        padding: 4px;
        background-color: $blue-dark;
        color: #fff;
        border-radius: 5px;

        &.location {

            margin: 0 5px 0 0;
            background-color: #ad56ad;
        }

        &.points {
            background-color: #648f14;
        }
    }

    .imgwrp {
        position: relative;

        display: inline-block;

        span {
            top: 5px;
            left: 5px;
            display: block;
            position: absolute;
        }

        img {
            width: 150px;
            height: 150px;
            object-fit: cover;
            border-radius: 6px;
        }

        &:hover {
            span {
                opacity: .2;
            }
        }
    }

}


.exchange {
    padding: 10px;
    background-color: $blue-extra-light;
    margin: 0 0 10px;
    border-radius: 3px;
    cursor: pointer;
    @include box-shadow-sm;

    &.demande {
        background-color: $orange-extra-light;
    }

    &:hover {
        filter: brightness(105%);
    }

    &:last-child {
        margin-bottom: 0;
    }

    .when {
        font-size: .82em;
    }

    .header {
        display: flex;
        justify-content: space-between;
    }

    .thumb {
        padding: 5px 0;

        img {


            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            width: 150px;
            height: 150px;
            object-fit: cover;
            border-radius: 6px;
        }
    }


    .controls {
        button {
            cursor: pointer;
            background: 'transparent';
            border: solid 1px $blue-dark;
            border-radius: 4px;
            height: 28px;
            width: 38px;
            margin: 0 5px 0 0;

            &:hover {
                filter: brightness(80%);
            }

            &:last-child {
                margin: unset
            }


            &.view {
                border-color: $blue;
                background-color: $blue;
                color: #fff;
            }

            &.edit {
                border-color: $btn-green-border;
                background-color: $btn-green;
                color: #fff;
            }

            &.delete {
                border-color: $pink-dark-border;
                background-color: $pink-dark;
                color: #fff;
            }
        }
    }

}

@media screen and (max-width: 800px) {
    .exchange {

        .header {
            display: block;

        }

        .controls {
            margin-top: 8px;
        }


    }
}

@media screen and (max-width: 600px) {
    .container {
        .list {

            height: calc(100vh - 120px);
        }
    }
}