/* START MAIN IMPORTS*/
@use "./config/colors" as *;
@import "./fonts/roboto";
@import "./fonts/opensans";

$primary-font: "Roboto";


*{
    $border-box:border-box;
    -webkit-box-sizing: $border-box;
    -moz-box-sizing: $border-box;
    box-sizing: $border-box;
    outline: none;

    &::before,
    &:after{
        -webkit-box-sizing: $border-box;
        -moz-box-sizing: $border-box;
        box-sizing: $border-box;
    }
    *:focus {
        outline: 0px none transparent;
    }
}
  
button::-moz-focus-inner {
    border: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: $primary-font, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12pt;
  color:#555;
}

a{
  color: $blue-dark;
}
.small-link{
    text-decoration: none;
    font-size: .9em;
    &:hover{
        text-decoration: underline;
    }
}
p{
    line-height: 1.3em;
}

.inline {display: inline}
.block{display:block;}
.right{text-align: right;}
.mg-10{margin:10px 0;}
.pointer{cursor: pointer;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

hr {
  display: block;
  margin: 10px 0;
  height: 2px;
  background-color: $white;
  color: #999;
  border: none;
  border-top: solid 1px #ccc;
}

div.page-content {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow:auto;
    &.np {
        padding: 0;
        overflow: unset;
    }
}

.flex { 
    display: flex;
    &.w{
        justify-content: space-between;
    }
    &.end{
        justify-content: end;
    }
    &.buttons {
        > button {
            margin-right: 5px;
            &:last-child{
                margin-right: unset;
            }
        }
    }
    &.d {
            > div{
                margin-right:10px;
            }
    }
}

.no-select {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:focus{
        outline: none !important;
    }
}

mark{
    font-style: normal;
    font-weight: normal;
}

ul{
    li{
        line-height: 1.3em;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px white inset;
    -webkit-text-fill-color:#171096;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #171096;
}
.ck-editor__editable_inline{
    min-height: 150px;
}

figure.table{
    margin: 0;
    padding: 0;
    width: 100%;
    table{
        width: 100%;
        border-collapse: collapse;
        th, td{
            border: solid 1px #ccc;
            padding: 5px;
            text-align: left;
        }
        th{
            background-color: #f0f0f0;
        }
    }
}