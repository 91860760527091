@use "../../styles/config/colors" as *;

.control {
    border: solid 1px $blue;
    align-items: center;
    border-radius: 5px;
    display: flex;

    &.warn{
        border-color: $pink-dark;
    }

    input[type="password"],
    input[type="text"] {
        width: 100%;
        border-radius: 5px;
        border: none;
        background-color: transparent;
    }

    svg {
        flex-shrink: 0;
        margin-right: 10px;
        cursor: pointer;
        color: $blue-dark;
    }
}