@use "../../styles/config/colors" as *;

.container {
    padding: 0 10px;
 

    .heading{
        display: flex;
        justify-content: space-between;
    }

    .sorter{
        margin-bottom: 10px;
 
        div{
            button{
                cursor: pointer;
                border:none;
                background-color: #ccc;
                padding:3px 8px;
                border-radius: 5px;
                font-size: .8em;
                margin-right: 5px;

                &.active{
                    background-color: $blue-dark;
                    color:#fff;
                 
                }
            }
        }
    }

    .heading {
        padding: 10px 0 10px 0;
    }

    .options {
        display: flex;

        a {
            font-size: .8em;
            display: inline-block;
            text-decoration: none;
            color: #333;
            padding: 4px;

            &.linkactive {
                background-color: $blue-dark;
                color: #fff;
                border-radius: 4px;

            }

            margin-right: 5px;

            &:last-child {
                margin-right: unset;

                &::after {
                    content: unset;
                    display: unset;
                }
            }
        }
    }


    .invitebtn {
        padding: 8px 12px;

        span {
            display: inline-block;
            margin-right: 10px;
        }
    }

    .innerContainer {
        margin: 20px 0 0 0;
        height: calc(100vh - 185px);
        overflow: auto;
    }

    .groupUsers {
        margin-top: 20px;
        max-width: 800px;

        h3 {
            color: $blue-dark;

            span {
                color: $blue-pen;
                font-weight: normal;
                font-size: .82em;
            }

            margin-bottom: 10px;

            svg {
                cursor: pointer;

                &:active {
                    color: #5C0060
                }

                &:hover {
                    filter: brightness(120%);
                }
            }
        }

        .u {
            width: 100%;
            margin-bottom: 10px;
            display: flex;
            align-items: flex-start;

            &>div{
                flex-grow: 1;   
                
                &.exchanges{
                    p{
                        text-align: end;
                    }
                }
            }

            p{
                font-size: .9em;
            }

            .n {
                padding: 4px;
                margin-right: 10px;
                font-size: .9em;
                display: inline-block;
                width: 280px;

                text-decoration: none;

                span {
                    display: block;
                    color: #333;
                    margin: 5px 0 0;
                    font-size: .9em;
                }

                &:hover {
                    background-color: #d7e5ef;
                }
            }

            .d {
                color: $orange;
                font-size: .9em;
                &.center{
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                }
            }

            .join{
                margin-right:25px;
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .container {
        .innerContainer {
            margin: 0;
            height: calc(100vh - 135px);
            overflow: auto;
        }
        .groupUsers {
            .u {
                margin-bottom: 10px;
                display: block;

                &>div{
                    flex-grow: 1;   
                    
                    &.exchanges{
                        p{
                            font-size: .9em;
                            color: $blue-pen;
                            margin:2px 0 0;
                            text-align: start;
                        }
                    }
                }
    
                p{
                    font-size: .9em;
                }
    
                .n {
                    padding: 0;
                    margin: 0;
                    display: inline-block;
                    width: unset;
    
                    span {
                        display: block;
                        color: #333;
                        margin:0 0 3px;
                        font-size: .9em;


                    }
    
                    &:hover {
                        background-color: #d7e5ef;
                    }
                }
    
                .d {
             
                    font-size: .9em;
             
                    &.center{
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                    }
                }
    
                .join{
                    margin-right:0;
                    p{
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}