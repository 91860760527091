.container{
    padding:20px;

    h1{
        margin: 0 0 20px;
    }
    h5{
        font-weight: normal;
        margin: 0 0 30px;
    }
    .form{
        width: 500px;
        margin: 0 auto;

        label{
            font-weight: normal;
            font-size:1.1em;
        }

        p{
            font-size: .78em;
        }

        .warning{
            margin:10px 0 0 0;
        }

        .row{
            margin-bottom: 15px;

            >p{
                margin-bottom: 6px;
            }
            &.buttons{
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

@media screen and (max-width: 600px){
    .container{
 
        .form{
            width: 100% !important;

            label{
                font-size: 1em;
            }
        }
    }
}