@use "../../styles/config/colors" as *;

.element {
    display: flex;
    padding: 8px 4px;
    cursor: pointer;
    align-items: center;

    span {
        color: $blue-dark;
        font-size: .9em;

        margin-right: 5px;
    }

    svg{
        font-size:.83em;
    }

    &:hover{
        background-color: #dfdfdf;
        border-radius: 4px;
    }

    margin-right: 10px;

}