
.container {
    

    .heading{
        background-color: #e8f3f7;
        padding:20px;
        border-bottom: solid 1px #ccc;
    }
    .content{
        padding:20px;
    }

    a{
        text-decoration: none;
        &:hover{
            text-decoration:underline;
        }
    }

    .message{
        background-color: #eaecec;
        padding: 10px;
        border-radius: 4px;
        margin:10px 0;
        font-size: .84em;
    }

    .form {
        max-width: 600px;
        margin: 20px 0 0 0;

        label {
            display: block;
            color: #2b6a94;
            margin: 0 0 6px;

        }

        .row {
            margin: 0 0 10px;

            &.buttons{
                >*{
                    margin-right: 5px;
                    &:last-child{
                        margin-right:unset;
                    }
                }
            }
        }

        .control {
            display: block;
            padding: 6px;
            border: solid 1px #bbb; 
            border-radius: 5px;
            font-size: .92em;
            color: #171096;
            outline: none;
            width: 100%;
            min-width: 80px;
        }
 
    }
}