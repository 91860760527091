@use './config/colors'  as *;

::placeholder {
    color: #ccc;
    font-size: .84em;
    font-style: italic;
}

input[type="text"].form-control,
input[type="number"].form-control,
input[type="password"].form-control,
select.form-control {
    display: block;
    padding: 6px;
    border: solid 1px #bbb;
    background-color: $text-input-yellow;
    border-radius: 5px;
    font-size: .92em;
    color: #171096;
    outline: none;
    width:100%;
    min-width:80px;
    &.readonly{
        background-color: #eeee;
        color:#666;        
    }
}

textarea.form-control {
    width: 100%;
    min-height: 60px;
    resize: vertical;
    border: solid 1px #ccc;
    padding: 4px;
    border-radius: 5px;
}
.validation-warning{
    border-color: $red-validation !important;
    background-color:  $red-validation-background !important;
}

input:disabled, textarea:disabled{
    background-color: #ededed !important;
    color:#888 !important;
    cursor:not-allowed;
}