@use './config/colors' as *;

.container{

    label{
        cursor: pointer;
        display: flex;
        align-items: center;

        input{
            margin: 0;
            -webkit-appearance: none;
            width: 1rem;
            height: 1rem;
            border: 1px solid darkgray;
            border-radius: 50%;
            outline: none;
            &:checked {
                background-color: $blue-dark;
            }
        }

        span{
            font-weight: normal;
            display: block;
            margin: 0 0 0 5px;
            font-size: 1em;
            color:$blue-dark;
        }
    }
}