@use "../../styles/config/colors" as *;
@use '../../styles/config/shadows' as *;

.container {
    margin-top: 10px;

    .heading {
        p {
            color: $blue-light;
        }
    }

    .radio{
        font-size: .8em;
    }

    .warn {
        color: #b94c0d;
    }

    .docs {
        height: calc(100vh - 380px);
        overflow: auto;
        margin-top: 10px;
    }

    .document {
        margin-bottom: 10px;
        padding: 10px 5px 10px 10px;
        border: solid 1px #dedede;
        border-radius: 3px;
        width: 350px;
        background-color: #f0f0f0;


        &:hover {
            background-color: #f8f8f8;
        }

        .m {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-width: 0;

            .t {
                overflow: hidden;
                width: calc(93%);
                font-size: .84em;
                text-overflow: ellipsis;
                display: inline-block;
                white-space: nowrap;
            }


            .opt {
                width: 20px;
                margin: 0 0 0 5px;
                cursor: pointer;

                &>svg {
                    width: 18px;
                    margin: 0 auto;
                    font-size: 1.3em;
                }

                &:hover {

                    color: #5786E5;

                }

                .controls {
                    position: absolute;
                    background-color: #fff;
                    border: solid 1px #e0e0e0;
                    @include box-shadow-sm;

                    display: flex;
                    flex-direction: column;

                    button {
                        display: flex;
                        cursor: pointer;
                        border: none;
                        border-bottom: solid 1px #ddd;
                        padding: 6px 8px;
                        background: transparent;

                        svg {
                            width: 16px;
                        }

                        &:hover {
                            background-color: #fcfcda;
                        }

                        &.delete {

                            color: $pink-dark-border;
                        }

                        &.download {

                            color: $blue-dark;
                        }

                        &.link {

                            color: $btn-green-border;
                        }
                    }
                }
            }
        }

        svg {
            margin-right: 5px;

            &.pdf {
                color: #c20a0a;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .container {

        .docs {
            height: calc(100vh - 317px);
        }
        .document {
            width: auto;
        }
    }
}