@use '../../styles/config/colors' as *;

.container {
    padding: 10px;

    .f {
        display: flex;
        align-items: center;

        a {
            cursor: pointer;
            width: 15px;
            display: inline-block;
            margin: 2px 0 0 0;
            color: #555;

            &:hover {
                color: $blue;
            }
        }


        h3 {
            margin: 0;
            font-size: 1.8rem;
            font-weight: normal;
            color: $blue-dark;
        }

        margin-bottom:15px;
    }
    

    .collection {
        height: calc(100vh - 136px);
        overflow: auto;

        .tag {
            display: inline-block;
            background-color: #fff;
            font-size: .7rem;
            padding: 4px;
            background-color: $blue-dark;
            color: #fff;
            border-radius: 5px;
    
            &.location {
    
                margin: 0 5px 0 0;
                background-color: #ad56ad;
            }
    
            &.points {
                background-color: #648f14;
            }
        }
       
        img {
            width: 150px;
            height: 150px;
            object-fit: cover;
            border-radius: 6px;
        }
    }


    .exchange {
        text-decoration: none;


        display: flex;
        justify-content: space-between;
        align-items: center;

        &.demande {
            background-color: $orange-extra-light;
        }

        &:hover {
            filter:brightness(105%);
        }

        padding: 10px;

        h4 {
            margin: 0 0 6px;
        }


        .pb {
            font-size: .84em;

            strong {
                display: inline-block;
                margin: 0 3px 0 0;
            }

            margin-bottom: 5px;
        }

        .sub {
            font-size: .84em;
            span{
                margin-right: 5px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }

        border-bottom: solid 1px #e3e3e3;

        margin-bottom: 10px;

    }
}

@media screen and (max-width: 600px) {
    .container {
        .collection {
            height: calc(100vh - 88px);
        }
    }
}