@use './config/colors' as *;

.container {
    position: relative;
    background-color: $blue;
    border-bottom: solid 1px $blue;
    padding: 8px 10px;
    height: 53px;
    overflow: hidden;

    .leftside,
    .rightside {
        display: flex;
        align-items: center;
    }

    .mobilemenu {
        display: none;

        svg {
            color: #fff;
            margin-right: 10px;
        }
    }

    .publishbutton {
        background-color: #D64408;
        border-color: #D64408;
        padding: 8px 10px;
        color: #fff;
        margin: 0 15px 0 0;
    }

    >div {
        display: flex;
        justify-content: space-between;
        align-items: center
    }

    .profile {
        $w: 36px;
        cursor: pointer;

        &:hover {
            svg {
                color: $orange;
            }
        }

        svg {
            color: #fff;
            height: $w;
            width: $w;
        }
    }

    .eclipse {
        cursor: pointer;
        height: 36px;
        width: 36px;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .logo {
        width: 200px;
    }

    .notice-view {
        position: relative;
        margin-right: 15px;

        span {
            position: absolute;
            display: block;
            background-color: $pink-dark;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            color: $white;
            font-weight: bold;
            font-size: .86em;
            top: -6px;
            right: -9px;
            text-align: center;
            padding-top: 6px;
        }
    }
}

@media screen and (max-width: 600px) {
    .container {
        height: unset;
        padding: 4px 6px;

        .mobilemenu {
            display: block;

            svg {
                font-size: 1.5em;
            }
        }

        .publishbutton {

            padding: 6px;
            margin: 0 5px 0 0;
            font-size: .84em;
        }

        .logo {
            width: 130px;
        }

        .mng-usr-profile {
            $w: 26px;
            border: solid 1px $white;
            border-radius: 50%;
            background-position: center center;
            height: $w;
            width: $w;
            margin-left: 5px;
        }

        .notice-view {
            display: none;
        }
    }
}