.button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border: solid 1px #aaa;
    border-radius: 3px;

    &.icon {
        padding: 2px;

        svg {
            line-height: normal;

        }
    }

    &.sm {
        svg {
            font-size: 1.3em;
        }
    }

    &.cancel {
        border-color: #cf457c;
        background-color: #cf457c;

        svg {
            color: white;
        }
    }


    &:hover {
        filter: brightness(115%);
    }

    &:active {
        box-shadow: 1px 1px 1px #aaa;
        filter: brightness(95%);
    }

}