@use './config/colors' as *;

.progressbar {
    display: block;
    margin:5px 0;

    > div{
        content: ' ';
        background-color: $blue;
        height: 6px;
        width: 0;
    }
}