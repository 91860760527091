@use './config/colors' as *;

.form {
    padding: 10px;

    label {
        display: block;
        font-size: 1em;
        margin: 0 0 8px;
        color: $blue-dark;
        font-weight: bold;

        &.inline {
            display: inline-block;
            margin: 0 5px 3px 0;
        }
    }

    .required {
        color: $red-validation;
    }

    .tiny-info {
        font-size: .88em;
        color: #777;
        font-style: italic;

        &.warn {
            color: $red-validation;
        }
    }

    h1 {
        font-size: 1.8rem;
        color: $blue-dark;
        font-weight: normal;
 
    }

    h2{
        font-size: 1.8rem;
        color: $blue-dark;
        font-weight: normal;
        margin:0 0 20px;
    }

    .row {
        width: 100%;
        padding: 10px 0;

        &.pr7 {
            padding-right: 7px;
        }

        &.pr10 {
            padding-right: 10px;
        }

        &.w25 {
            width: 25%;
        }

        &.w33 {
            width: percentage(4/12);
        }

        &.w50 {
            width: 50%;
        }

        &.w75 {
            width: 75%;
        }

        >p {
            margin: 10px 0;
        }

        &.separator {
            margin: 25px 0 1 0px 0;
        }

        &.extra-space {
            margin-bottom: 50px;
        }

        &.buttons {
            >button, >a {
                margin-right: 5px;

                &:last-child {
                    margin-right: unset;
                }
            }

            &.right {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .ctrl-3 {
        display: flex;
        width: 100%;

        >div {
            margin: 0 10px 0 0;
            width: percentage(4/12);

            &:last-child {
                margin: 0;
            }
        }
    }

    section {
        margin-bottom: 60px;

        &:last-child {
            margin-bottom: unset;
        }
    }

    .row-info {
        display: flex;
        width: 100%;

        .section-container {
            width: calc(100% - 60px)
        }

        .row {
            padding-top: 0;
        }
    }

    .error-message {
        line-height: 1.3em;
        display: block;
        width: inherit;
        margin: 5px 0 0 10px;
        font-size: 0.84em;
        color: $red-validation;
        width: 100%;

        &.ico {
            padding: 1px 0 0 18px;
            background-repeat: no-repeat;
        }
    }

    &.profile,
    &.pwd {
        max-width: 600px;
    }

    &.offer {
        max-width: 1000px;

        .row {
            >p {
                margin-bottom: 25px;
                font-size: .84em;
            }
        }

        &:last-child {
            margin: 0;

        }
    }

    &.invitation {
        max-width: 600px;

        .row {
            >p {
                margin-bottom: 25px;
                font-size: .84em;
            }
        }
    }

    &.pwdreset {
        max-width: 600px;
        margin: 0 auto;

        .row {
            >p {
                margin-bottom: 25px;
                font-size: .84em;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .form {

        padding: 5px;

        h1 {
            font-size: 1.1rem;
            margin-bottom: 5px;
        }

        section {
            margin-bottom: 5px;
        }

        .row-info {
            display: block;
            position: relative;

            .section-container {
                width: auto;
                margin-bottom: 25px;

                &:last-child {
                    margin-top: 0;
                }

                .row {
                    padding: 3px 0 0 0;

                    label {
                        margin: 5px 0;
                    }

                    &>label {
                        margin: 0 0 10px 25px;
                    }

                    &>div.flex {
                        label {
                            margin: 0 0 10px 25px;
                        }
                    }

                }
            }

        }

        &.offer {
            .row {
                padding: 0;

                p {
                    margin: 0 0 8px 0;
                    font-size: .7em;
                }
            }
        }

        .error-message {
            margin: 5px 0 10px;
            font-size: 0.7em;
        }

        .ctrl-3 {
            display: block;


            >div {
                width: 100%;
                margin: 0 0 10px 0;

                &:last-child {
                    margin: 0;
                }
            }
        }

    }
}