@use "../../styles/config/colors" as *;

.upload-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 300px;

    >* {
        margin-bottom: 10px;
    }
}

.profileform {
    >div {
        margin: 0;
        padding: 7px 0 !important;
    }

    label {
        margin-bottom: 5px;
    }

    p,
    span {
        font-size: .9em;
    }
}

.timezones {
    width: 100%;
    display: flex;

    >div {
        width: 100%;
        margin-right: 5px;

        &:last-child {
            margin-right: unset;
        }
    }
}

.scroll {
    height: calc(100vh - 90px);
    overflow: auto;
}


.geo {
    padding: 10px 0;

    button {
        svg {
            margin: 0 5px 0 0;
        }
    }

}

.deleterow {
    display: flex;
    align-items: flex-end;

    input {
        max-width: 200px;
        margin: 0 10px 0 0;

        &.inputwarn {
            background-color: $red-validation-background !important;
            border-color: $red-validation !important;
        }
    }
}

.selectwarn {
    border-color: $red-validation !important;
}

p {
    &.warning {
        color: $red-validation;
    }
}

@media screen and (max-width: 600px) {
    .scroll {
        height: calc(100vh - 70px);
        overflow: auto;
    }
}