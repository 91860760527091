@use "../../styles/config/colors" as *;

.container {

    border: solid 1px #ccc;
    border-radius: 4px;
    background-color: #f0f0f0;
    padding: 2px 10px 10px 10px;



    .options {
        display: flex;

        align-items: center;
        margin: 10px 0;

        .leftgroup {
            display: flex;
            width: 100%;

            button{
                margin-right: 10px;
            }
        }

        button {
            flex-shrink: 0;
            padding:9px;
            svg {
                margin: 0;
            }
        }

        .search {
            width: 70%;
        }
    }

    .text {
        margin: 10px 0 0;
        display: flex;

        input {
            display: block;
            width: 100%;
            padding: 4px;
            border: solid 1px #ccc;
            color: #333;

            &::placeholder {
                color: #555;
            }

        }
    }
}