.item {
    p {
        margin: 0 0 2px;
        font-size: 1.2em;
    }

    span {
        font-size:1.05em;
        color: #2861b6;
 

        &.example {
            display: block;
            margin: 5px 0 0 0;
            font-size: .8em;
            line-height: 1.3em; 
            color: #333;
        }
    }

    &.selected {
        span {
            color: #fff;
        }
    }
    .subitem{
        padding: 0 0 0 7px;
    }
}