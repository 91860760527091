@use "../../../styles/config/colors" as *;

.container {
    padding: 20px;

    h3 {
        margin-bottom: 20px;
    }

    .warning {
        max-width: 200px;
        background-color: #c78a51;
        padding: 5px;
        border-radius: 5px;
        color: #fff;
        font-size: .84em;
    }

    .match {

        p {
            margin-bottom: 10px;
        }

        span {
            margin: 0 0 0 10px;
        }
    }

    .success {
        h2 {
            svg {
                color: $btn-green;
                margin-right: 10px;
            }

            color:$blue-dark;
            font-size: 2em;
        }

        h3 {
            color: $blue-pen;
            font-size: 1.2em;
            margin: 10px 0 0 34px;
        }

        p {
            margin: 60px 0 0 34px;
            font-size: 1.5em;

            span {
                font-size: 1.4em;
                font-weight: bold;
                color: #000;
            }
        }

        .return {
            margin: 20px 0 0 0;
        }
    }

    .comment {
        max-width: 500px;
    }

    .buttons {
        display: flex;
        align-items: center;

        .warning {
            margin-right: 10px;
        }

        button {
            margin-right: 10px;
        }
    }

    .cancellation {
        position: relative;

        .c {
            display: block;
            position: absolute;
            background-color: $pink-dark;
            transform: rotate(40deg);
            width: 180px;
            text-align: center;
            padding: 4px;
            top: 30px;
            opacity: .5;
            color: #fff;
        }
    }

    .form {
        max-width: 800px;
        padding: 0;

        .buttons {
            margin: 20px 0 0 0;
        }

        .match {
            margin: 20px 0 0 0;

            i {
                color: #bd1ca7;
                font-size: .92em;
                display: inline-block;
                margin: 0 3px;
            }
        }

        ul {
            margin: 15px 0 0 0;

            li {
                margin: 0 0 0 20px;
                color: $blue;
            }
        }
    }
}