.container {

    .warning {
        position: absolute;
        z-index: 100;
        display: flex;
        padding: 10px;
        background-color: #d17160;
        border: solid 1px #c96b5a;
        border-radius: 6px;
        color: #fff;
        transform: translate(5px, 5px);

        button {
            border: none;
            background: transparent;
            color: #fff;
            margin: 0 10px 0 0;
            cursor: pointer;
        }

        p {
            font-size: .92em;
        }

        a {
            color: #f5f100
        }
    }

    .results {
        padding: 10px;
        height: calc(100vh - 210px);
        overflow: auto;
    }

    .spinner{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .paging {
        padding:10px;
    }
}

@media screen and (max-width: 900px) {}

@media screen and (max-width: 600px) {
    .container {
        .results {
            height: calc(100vh - 190px);
        }
    }
}