.page {

    .heading {
        font-family: 'open sans';
        padding: 0 10px 0;

        h1 {
            font-weight: normal;
            font-size: 1.4em;
        }
    }

    nav {
        display: flex;
        justify-content: flex-start;
        font-size: 1.2em;
        font-family: 'open sans';
        font-weight: normal;

        >a {
            min-width: 60px;
            padding: 10px 0 3px 0;
            color: #555;
            text-decoration: none;
            margin-right: 20px;

            >div {
                display: flex;
                align-items: center;
                justify-content: center;

                mark {
                    display: flex;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    background-color: #999;
                    margin-left: 8px;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    >span {
                        font-size: .6em;
                        color: #fff;
                    }

                    &.dot {
                        height: 6px;
                        width: 6px;
                        background-color: #ff7b00;
                    }
                }
            }

            &.active {
                color: #328853;
                border-bottom: solid 3px #328853;
            }


            &.o {
                &.active {
                    color: #2e569b;
                    border-bottom: solid 3px #2e569b;

                    mark {
                        background-color: #2e569b;
                    }
                }
            }

            &.d {
                &.active {
                    color: #a86a44;
                    border-bottom: solid 3px #a86a44;

                    mark {
                        background-color: #a86a44;
                    }
                }
            }
        }
    }

    .central {
        display: flex;

        .sections {
            padding: 10px 10px 10px 15px;
            background-color: #f0f0f0;
            flex-grow: 1;
            height: calc(100vh - 126px);
            overflow: auto;

            .tiles {
                display: flex;
                flex-wrap: wrap;

                justify-content: flex-start;
                gap: 20px;
            }

            h1 {
                color: #2e569b;
                font-size: 2em;
                font-family: 'open sans';
                font-weight: normal;
                padding: 10px;

                &.demande {
                    color: #a86a44;
                }
            }

            .demande {
                margin-top: 50px;

                h1 {
                    color: #a86a44;
                }
            }

        }

        .people {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 20px;
            padding: 10px;
            align-items: flex-start;
            flex-shrink: 0;
            width: 260px;

            height: 100%;
            max-height: calc(100vh - 126px);
            overflow: auto;

            >* {
                cursor: pointer;
            }

            .focus {
                background-color: #eee5f5;
            }
        }
    }

}

@media (max-width: 980px) {
    .page {
 
        .central {
            .sections {

                h1 {
                    font-size: 1.5em;
                    padding: 0 0 10px;
                }

            }

            .people {
                width: 130px;

            }
        }
    }
}

@media (max-width: 600px) {
    .page {

        .central {
            .sections {

                h1 {
                    font-size: 1em;
                    padding: 0 0 10px;
                }

            }

            .people {
                width: 110px;
                gap: 5px;
                padding: 5px;
            }
        }
    }
}