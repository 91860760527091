@use "../../styles/config/colors" as *;

.control {
    .overflow {
        display: flex;
        max-height: 300px;
        overflow: auto;
        flex-direction: column;
    }

    label{
        display: block;
        margin: 0 0 5px;
        font-size: .84em;
    }
    span {
        &.filteralert {
            display: block;
            font-size: .8em;
            margin: 0 0 10px 5px;
        }
    }

    .quickbtns {
        padding: 5px 0 0 0;

        button {
            background-color: #6f6f94;
            cursor: pointer;
            border-radius: 3px;
            border: solid 0px;
            color: #fff;
            padding: 3px 4px;
            margin: 0 5px 0 0;

            &:last-child {
                margin: unset;
            }

            &:hover {
                filter: brightness(1.1);
            }

        }

        margin:0 0 10px;
    }

    .search {
        width: 100%;
        display: flex;
        border: solid 1px #ccc;
        padding: 4px;
        border-radius: 6px;
        margin-bottom: 5px;

        input {
            width: inherit;
            border: none;
            color: $blue-dark;
        }

        button {
            background: none;
            cursor: pointer;
            border: none;

            svg {
                color: $blue-dark;
            }
        }
    }

    .groupcollection {


        margin-bottom: 10px;

        .group {
            display: flex;
            align-items: center;
            padding: 3px;
            font-size: .9em;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            svg {
                margin-right: 5px;
                font-size: 1.4em;
                color: #6f6f94;
            }

            &.mine {
                svg {
                    color: #5ba070
                }
            }

            button {
                padding: 0;
                margin: 0;
                background: none;
                border: none;

                &.clear {
                    svg {
                        color: #7e1728;
                        cursor: pointer;

                        &:hover {
                            filter: brightness(1.5);
                        }

                        &:active {
                            filter: brightness(0.8);
                        }
                    }
                }
            }

        }
    }


    .footer {
        span {
            font-size: .86em;
            color: $blue-dark;
        }

        border-top:solid 1px #ccc;
        padding:6px 0;
        margin:2px 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}