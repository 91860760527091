@use "../../styles/config/colors" as *;

.container {
    margin-top: 20px;

 

    .legend {
        flex-wrap: wrap;
        align-items: start;

        span {
            width: 30%;
            min-width: 60px;
            font-size: .8em;
            margin: 0 5px 5px 0;

            &.state {
                align-items: start;
                flex-shrink: 0;
            }
        }

        margin: 0 0 10px;
        background-color: #f0f0f0;
        padding:5px;
        border-radius: 5px;
    }

    .controls {
        display: flex;
        align-items: center;
        width: 100%;

        .selector {
            max-width: 200px;
            flex-grow: 1;
            margin-right: 20px;
        }
    }

 

    code {
        background-color: #d8d8d8;
        padding: 3px 6px;
        border-radius: 3px;
        color: #555;
        text-shadow: 1px 1px 1px #fff;

    }

    .searchfield{
        width: 100%;
        padding: 9px 6px;
        color:#555;;
        border: solid 1px #aaa;
        border-radius: 5px;
        font-size: 1em;
    }
}

  