.container{
    padding: 20px;

    .home{
        margin:20px 0 0 0;
        a{
        
           text-decoration: none;
        }
    }
  
}