.container {
    padding: 20px;

    h1{
        font-size: 1.4em;
        font-weight: normal;
        margin: 0 0 20px;
    }

    .testform {
        max-width: 500px;
        display: flex;


        .a {
     
           width: 100%;
        }

        .b {
            flex-shrink: 0;
            margin: 0 0 0 10px;
        }
    }
}