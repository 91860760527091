@use "../../styles/config/colors" as *;

.container {
    padding: 10px;

    .radio {
        display: flex;
        align-items: center;

        &>* {
            margin-right: 10px;
        }

        margin: 0 0 10px;
    }

    .filters {
        display: flex;
        align-items: flex-end;
        padding: 10px;
        border-bottom: solid 1px #dadada;


        label {
            font-weight: bold;
            display: block;
            margin: 0 0 3px;
            font-size: .9em;
        }

        .picker {
            width: 150px;
            margin-right: 10px;
        }

        .selector {
            width: 150px;
            margin-right: 10px;
        }

        .query {
            max-width: 200px;
            margin-right: 10px;
        }

        .hourinput {
            width: 140px;
            margin-right: 10px;
        }

        .btn {
            display: flex;

            button {
                margin-right: 5px;
            }
        }
    }

    .f {
        display: flex;
        justify-content: center;
        background-color: #f0f0f0;
        padding: 5px;
    }
}

.logstable {
    font-family: Consolas, Arial;

    .overflow {
        height: calc(100vh - 250px);
        overflow: auto;
    }

    table {
        table-layout: fixed;
        width: 100%;

        &.h {
            th {
                text-align: center;
                padding: 4px;
                background-color: #ddd;
                color: #333;
                font-weight: normal;

            }
        }

        &.d {

            td {
                padding: 10px 4px;
                font-size: .82em;
                vertical-align: middle;
                text-align: center;

                textarea {
                    width: 100%;
                    height: 50px;
                    border: none;
                    color: #0f4ac1;
                }

                ;
            }

            tr {
                &:nth-child(odd) {
                    background-color: #f0f0f0;
                }
            }
        }
    }

    .stack {
        color: #0f4ac1;
        display: flex;
        align-self: center;
        justify-content: flex-end;

        span {
            margin-right: 4px;
        }

        svg {
            cursor: pointer;
        }
    }

    .stacktrace {

        text-align: left;
        line-height: 1.2em;

        .content {
            display: none;
        }

        &.show {
            .content {
                display: block;
            }
        }
    }
}

.pager {
    display: flex;
    align-items: center;

    button {
        background-color: $blue-dark;
        border: none;
        color: #fff;
        padding: 3px 10px;
        cursor: pointer;

        &:active {
            filter: brightness(90%)
        }
    }

    span {
        font-size: .84em;
        display: block;
        padding: 0 5px;
    }
}

@media screen and (max-width: 600px) {
    .logstable {
        .overflow {
            height: calc(100vh - 275px);
        }
    }
}