    @use "../../styles/config/colors" as *;
    @import '../../styles/mixins';

    .container {
        font-family: "Open Sans";
        background-color: #f0f0f0;
        padding: 0 10px;


        &.white {
            background-color: #fff;
        }

        .nocontent {
            width: 250px;
            margin: 40px auto 0;

            h3 {
                font-size: 1.3em;
                margin: 0 0 50px;
            }
        }


        .exchange {
            max-width: 1200px;
            margin: 0 auto;
            border: solid 1px #ddd;
            padding: 20px;
            height: calc(100vh - 90px);
            overflow: auto;
            background-color: #fff;



            .titlearea {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .title {
                    h1 {
                        font-size: 1.3em;
                        margin: 0 0 2px;
                    }

                    strong {
                        margin-right: 10px;
                        display: block;
                        background-color: #648f14;
                        color: #fff;
                        padding: 5px;
                        border-radius: 4px;
                        flex-shrink: 0;
                    }

                    span {
                        font-size: .84em;
                    }

                    .titlegroup {
                        display: flex;
                        align-items: center;
                    }
                }

                margin: 0 0 10px;
            }

            h5 {
                margin: 0 0 5px;
                font-weight: normal;
                color: $blue-light;
            }

            .topcontrols {
                display: flex;
                padding: 0 0 5px;
                justify-content: space-between;

                border-bottom: solid 1px #ccc;

                .left {
                    display: flex;

                    .offerbtn {
                        background-color: #D64408;
                        color: #fff;
                        border: none;
                        border-radius: 3px;

                    }
                }

                .right {
                    display: flex;

                    >* {
                        margin-right: 5px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    .tag {
                        border-radius: 5px;
                        font-size: .82em;
                        padding: 6px 8px;
                        color: #fff;
                        line-height: 1em;
                   

                        &.offer {
                            background-color: #7a9dd8;
                        }

                        &.demande {
                            background-color: #cc9e82;
                        }

                        &.p100 {
                            background: linear-gradient(to bottom, #fcd04b 0%, #febf01 100%);
                            font-size: .8rem;
                            color: #4f632d;
                        }
                    }
                }





                .c {
                    font-size: .9em;

                    &:after {
                        content: " ";
                        border-right: solid 2px $blue;
                        border-bottom: solid 2px $blue;
                        display: inline-block;
                        margin: 0 8px 0 4px;
                        height: 9px;
                        width: 9px;
                        color: blue;
                        transform: rotate(-45deg);
                    }

                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                }

                .button {
                    text-decoration: none;
                    display: block;
                    padding: 6px;
                    border-radius: 3px;
                    margin: 0 5px 0 0;

                    &.edit {
                        background-color: $blue-dark;
                        color: #fff;
                        border: none;
                    }

                    &.search {
                        background-color: #764ea5;
                        color: #fff;
                        border: none;

                    }
                }


            }

            .body {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 10px 0 0 0;

                .main {
                    padding: 0 10px 0 0;


                    .imagecollection {

                        width: 860px;
                        flex-shrink: 0;
                        margin: 0 20px 0 0;

                        .images {
                            @include noselect;
                            width: inherit;
                            height: 100%;

                            img {
                                max-width: 100%;
                                max-height: 100%;
                                margin: 0 auto;
                                display: block;
                                object-fit: contain;
                            }
                        }
                    }

                    .description {
                        margin-top: 30px;

                        h4 {
                            font-weight: normal;
                            font-size: 1.3em;
                            color: $blue-light;
                            margin: 0 0 15px;
                        }

                        .html {
                            padding: 0 10px 0 0;

                            ul {
                                li {
                                    margin: 0 0 0 20px;
                                    line-height: 1.5em;
                                    font-size: .9em;
                                }
                            }
                        }
                    }
                }

                .side {
                    width: 250px;

                    .mapwrapper {
                        margin-top: 10px;
                        @include shadow-2dp;
                        padding: 4px;

                        .map {
                            width: 100%;
                            height: 300px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1460px) {
        .container {
            .exchange {
                .body {
                    .main {
                        .imagecollection {

                            width: 740px;
                        }
                    }
                }

            }
        }
    }

    @media screen and (max-width: 1300px) {
        .container {
            .exchange {
                .body {
                    .main {
                        .imagecollection {

                            width: 600px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1150px) {
        .container {
            .exchange {
                .body {

                    display: block;

                    .side {
                        margin: 20px 0 0;
                        display: flex;
                        width: 100%;

                        >* {
                            width: 50%;
                        }

                        .mapwrapper {
                            margin: 0 0 0 5px;
                        }

                    }

                    .main {

                        .imagecollection {

                            width: 100%;
                        }
                    }
                }

            }
        }
    }

    @media screen and (max-width: 800px) {
        .container {
            .exchange {
                .titlearea {
                    .title {
                        a {
                            display: none;
                        }

                        .titlegroup {
                            display: block;

                            strong {
                                display: inline-block;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }

                .body {

                    display: block;

                    .side {
                        display: block;

                        >* {
                            width: unset;
                        }

                        .mapwrapper {
                            margin: 10px 0 0 0;
                        }

                    }

                    .main {
                        .imagecollection {

                            width: 100%;
                        }
                    }
                }

            }
        }
    }

    @media screen and (max-width: 600px) {
        .container {
            background-color: unset;
            padding: 0;

            .exchange {
                border: none;
                border-radius: unset;
                padding: 20px;
                height: calc(100vh - 112px);

                overflow: auto;

                h1 {
                    font-size: 1.2em;
                    margin: 5px 0 10px;
                }

                .titlearea {
                    .title {


                        a {
                            display: none;
                        }

                        .titlegroup {
                            display: block;

                            strong {
                                display: inline-block;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }

            }
        }
    }