@mixin noselect{
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
@mixin gradient{
    background: linear-gradient(80.15deg, #EAE9FB 0%, #D1DBFF 100%);
}

@mixin shadow{
    -webkit-box-shadow: 0px 2px 17px 1px rgba(0,0,0,0.35); 
    box-shadow: 0px 2px 17px 1px rgba(0,0,0,0.35);
}
@mixin shadow-1dp{
    -webkit-box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.1); 
    box-shadow: 0px 1px 8px 1px rgba(0,0,0,0.1);
}
@mixin shadow-2dp{
    -webkit-box-shadow: 0px 2px 8px 1px rgba(0,0,0,0.15); 
    box-shadow: 0px 2px 8px 1px rgba(0,0,0,0.15);
}
@mixin shadow-active{
    -webkit-box-shadow: 0px 2px 17px 1px rgba(32, 99, 143, 0.35); 
    box-shadow: 0px 2px 17px 1px rgba(32, 99, 143, 0.35);
}
@mixin shadow-active-2pd{
    -webkit-box-shadow: 0px 2px 8px 1px rgba(32, 99, 143, 0.25); 
    box-shadow: 0px 2px 8px 1px rgba(32, 99, 143, 0.25);
}