.container{

    header{
        padding: 10px 20px;
        img{
            height: 50px;
        }
    }

    input[type="text"], 
    input[type="password"]{
        background-color: #fff !important;
        padding:8px;
        font-size: 1.2em;
    }

}