@use "../../../styles/config/colors" as *;

.container {
    padding: 10px;



    .filters {
        width: 100%;
        display: flex;
        align-items: center;

        >* {
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }

        .country {
            width: 150px;
        }

        .province {
            width: 200px;
        }

        .region {
            width: 250px;
        }

        .separator {
            margin: 0 10px;
            width: 1px;
            height: 38px;
            background-color: #bbb;
        }
    }

    .filterfield {
        display: flex;
        margin: 10px 0;
        width: 200px;
        display: flex;
        border: solid 1px #bbb;
        border-radius: 5px;
        padding: 2px 0 2px 2px;
        background-color: #fff;

        input {
            width: 100%;
            border: none;
            line-height: normal;          
            flex-shrink: 1;
            background-color: transparent;
        }

        div{
            height: 28px;
        }

        button {
            width: 36px;
            flex-shrink: 0;
            background: transparent;
            cursor: pointer;
            border: none;
            svg {
                color: #1E4586;
                transform: translate(0, 2px);

                &:active {
                    color: #0995cc;
                }
            }
        }
    }

    .userlist {
        height: calc(100vh - 237px);
        overflow: auto;

        ul {
            li {
                display: flex;
                align-items: center;
                list-style: none;
                line-height: 2em;

                a {
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }

                    span {
                        width: 300px;
                        display: inline-block;


                        &.email {
                            color: #504e4f;
                            margin-left: 10px;
                        }
                    }

                    &:hover {
                        background-color: #e6f7f5;
                    }
                }

                .info {
                    cursor: help;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    svg {
                        margin-left: 5px;
                    }
                }

            }
        }
    }

    .b {
        padding: 4px;
    }
}

.module {

    &.usersform {
        max-width: 600px;
    }

    .warn {
        input {
            border-color: $red-validation;
            background-color: $red-validation-background;
        }
    }

    .erroMsg {
        margin: 3px 0 0 7px !important;
        padding: 0;
        font-size: .84em;
        color: $red-validation;
    }

    h4 {
        margin: 0 0 5px;
    }

    .checkboxes {
        margin: 10px 0;

        div {
            display: flex;
            margin-bottom: 3px;

            label {
                font-size: .9em;
                font-weight: normal;
            }
        }
    }

    .inpbutton {
        display: flex;



        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            background-color: #f0f0f0;
            width: 50%;
        }

        button {
            cursor: pointer;


            svg {
                transform: rotate(0);
                -webkit-transition: -webkit-transform .1s ease-out;
                -moz-transition: -moz-transform .1s ease-out;
                -o-transition: -o-transform .1s ease-out;
                -ms-transition: -ms-transform .1s ease-out;
                transition: transform .1s ease-out;

            }

            &:active {
                filter: brightness(90%);

                svg {
                    transform: rotate(90deg);
                }
            }

            border: solid 1px #315494;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background-color: #2187b6;
            color:#fff;
        }
    }

    .groupheightlimit {
        max-height: 210px;
        padding: 4px;
    }

    .rowgroup {
        display: flex;

        >* {
            &:first-child {
                margin-right: 5px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .container {
        .userlist {
            height: calc(100vh - 213px);
        }
    }
}