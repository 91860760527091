@use '../styles/config/colors' as *;
.container {
    padding: 10px;


    .heading {

        h1 {
            font-size: 1.8rem;
            color: $blue-dark;
            font-weight: normal;
        }
    }
}