@use "../../styles/config/colors" as *;
@import '../../styles/mixins';


.sideinfo {
    padding: 10px;
    background-color: $blue-extra-light;
    color: #333;

    
    @include shadow-1dp;

    .r {

        margin-bottom: 20px;

        a {
            line-height: 1.5em;
            display: inline-block;

            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }

        h5 {
            color: $orange;
        }

        ul {
            max-height: 120px;
            overflow: auto;

            li {
                max-width: 230px;
                list-style: none;
                margin: 0;
                font-size: .82em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .avatar {
            border-radius: 50%;
            width: 80px;
            height: 80px;
            overflow: hidden;
            margin-bottom: 10px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .mapbtn {
        padding: 6px 6px 6px 0;

        svg {
            margin-right: 6px;
        }
    }
}
 
@media screen and (max-width: 600px) {
    .sideinfo {
        .r {
            a {
                max-width: 525px;
            }
        }
    }
}