.paginator {
    display: flex;
    justify-content: center;

    .nav {
        button {
            cursor: pointer;
            border: solid 1px #ccc;
            border-radius: 3px;
            margin: 0 2px 0 0;
            background-color: #1E4586;
            display: inline-block;
            padding: 2px 6px 0 6px;
            font-size: 1.2em;

            &.limits {
                background-color: #1E4586;
            }

            svg {
                color: #fff;
            }

            &:hover {
                filter: brightness(1.1);
            }


            &:disabled {
                filter: unset;
                cursor: not-allowed;
                background-color: #bbc7da;
            }

        }
    }

    .mid {
        margin: 0 10px;

        .pages {
            display: flex;
            justify-content: center;

            span {
                display: block;
                padding: 5px 10px;
            }

            button {
                cursor: pointer;
                min-width: 45px;
                margin: 0 3px 0 0;
                display: block;
                border: solid 1px #ccc;
                padding: 5px 15px;
                font-size: 1.1em;

                border-radius: 3px;
                background-color: #e8e8e8;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    filter: brightness(1.1);
                }

                &.active {
                    background-color: #1E4586;
                    color: #fff;
                    cursor: default;
                    filter: unset;
                }
            }
        }
    }

}

@media screen and (max-width: 680px) {
    .paginator {
        .nav {
            button {
                padding: 2px 4px 0 4px;
            }
        }

        .mid {
          

            .pages {
                button {
                    min-width: unset;
                    padding: 5px 10px;
                }
            }
        }
    }

}