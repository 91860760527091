@use "../../styles/config/colors" as *;

.container{
    h1{
        margin:0 0 10px;
        font-weight: normal;
        font-size: 1.4em;
        color:$blue-dark;
    }

    padding:20px;

    .input{
        width: 350px;
        
    }
    .info{
        display: flex;
        align-items: center;
        button{
            margin-left: 5px;
        }
    }
    button {
        padding: 0;
        margin: 0;
        background: none;
        border: none;

        &.clear {
            svg {
                color: #7e1728;
                cursor: pointer;

                &:hover {
                    filter: brightness(1.5);
                }

                &:active {
                    filter: brightness(0.8);
                }
            }
        }
    }
}