@use "../../styles/config/colors" as *;
@import "../../styles/mixins";


.main {
    overflow-x: auto;
    overflow-y: visible;
    border-bottom: solid 1px #ccc;

    .radiuscontrol {
        width: 100px;
    }

    .rangeslider {
        color: $blue-dark;
        transform: translate(0, 2px);
    }

    .textinput {
        display: flex;
        border: solid 1px #bbb;
        border-radius: 5px;
        padding: 2px 5px;
        width: 165px;

        input {

            border: none;
            font-size: .9em;
            color: #555;
            width: 100%;
        }

        button {
            flex-shrink: 0;

            &:active {
                filter: brightness(120%)
            }

            cursor: pointer;
            background-color: $blue-dark;
            color:#fff;
            border-radius: 50%;
            border:none;
            height: 20px;
            width: 20px;

            svg {
                font-size: .82em;
            }

            &.hide {
                visibility: hidden;
            }
        }
    }

    .filters {
        display: flex;
        align-items: center;
        min-width: 980px;

        padding: 10px 5px;

        &>div {
            padding: 0 4px;
            flex-shrink: 0;
        }

        .f1 {
            width: 170px;
        }

        .f2 {
            width: 155px;
        }

        .f3 {
            width: 200px;
        }

        .f4 {
            width: 190px;
            display: flex;
            align-items: center;

            >div {
                width: 100%;
            }

            >span {
                display: block;
                width: 65px;
                flex-shrink: 0;
                margin: 0 0 0 15px;
                font-size: .9em;

                &.inf {
                    transform: translate(10px, 0);
                    font-size: 1.2em;
                }
            }



        }

        .onlypts{
            width: 85px;
            font-size: .8em;
        }

        .ordergroup {
            display: flex;
            align-items: center;
            margin: 0 0 0 7px;

            svg {
                color: #5578a0;
                font-size: .9em;
            }

            margin-right: 10px;
        }

    }

    .searchctrl {
        display: flex;
        align-items: center;

        button {
            svg {
                margin: 0;
            }
        }

        .separator {
            width: 1px;
            margin: 0 15px;
            background-color: #ccc;
            height: 25px;
        }
    }

    .groupPreview{
        display: flex;
        align-items: center;

        svg{
            font-size: 1.3em;
            margin-right: 5px;
            color: #5ba070
        }

        margin-bottom: 8px;

        &:last-child{
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: 600px) {
    .main {
        .filters {
            padding: 20px 5px 0;
        }
    }
}