@use './config/colors' as *;
.notice{
    width: 100%;
    padding:5px 10px;
    display: flex;
    align-items: center;

    p{
        margin:0 !important;
    }


    &.info{
        border: solid 1px #ccc;
        background-color: $notice-light-green;
        &::before{
            display: block;
            content: ' ';
            background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAACiUlEQVRIidWVPUxTURiGn++W/gALsxqghI2w0OKsiagbxaGrFAbCRELEUuJwJ2lDjINTg6RhbaJSJxI0wVVvF0InAggaXRkQWkjv58BtU/sDLU5808397nmf97znnnPgppdc1jRzaY8vfxISJaToEHDHaf0Esohk8r6ONXMgfNYyIJFNPVFlCei7wuSuijEXCzz90BTAVNNot3riKswBKGwZyErRsD+5XW0HAMWC3YuhD4BJYNARSpwGDhZMMe1KvbZqQIV4AdWZQvBwuXoQkANypppvfNnuKZDXCtH2bA/AfMMZOLG8AwqGrY+e3534clk2pVr8lronwjrgFRiLBiNrpZ5RjiaX9jiZg+pMPfG4ldK4ldLq97HhyKbALIAiS0kr6a4B+PInIaBPYSsfPFxuxnll+fc6k4Jsg/YfqXu0BgA6BiDwtk7mAMwHIzIfjNT988LhcBHRFQBbJFQLUIYAbEM/t+q+LFGUDcdksM4M5BbAmff0sJFAozUoK5wVnbFyuw7g/+u0zevEp+WIK9fgF4Cn0N59XYDblS+N/V0HgAVg2DJyXYBLXA8BVPhaCxDJOE+TppotR5dOp13AxIVJ/VgDyPs61oBdYPBi+7dW+/7jaWAA2OmS85LZf4+KxezqmKj9Hiio8jg2HNlsRjxhrd5X7HXALSqh6PB4eQY1myZhpeIKUaAgMOvf60yGw+FiPeF0Ou3a9x9Pq8grwKPIYiw4vlD5Tf3jOtvz0oEgyDaiK1qUjXznnwMA94m318AYEZFJJxZVJF4IfH9RfQo0vnCsVEiRJdD+S9IB2BGVZ5WxNAUASFpJ95G6R22RkEAAxLky7R+okRXsTJecZ6aCU+dXmLjB9RdJKf2p4/bI6QAAAABJRU5ErkJggg==');
            height: 24px;
            width: 24px;
            margin-right: 10px;
        }
    }

    &.warn{
        border: solid 1px #ccc;
        background-color: $notice-light-yellow;
        &::before{
            display: block;
            content: ' ';
            background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAACT0lEQVRIie2UTUhUYRSGn3O7MwMxJdiPM+q2YEasrauQWkRCMKOozCgt24UaCELQKor+TKhN22pGU2emaGGUZbtwV4tQdFNocbXsBydBvXNPC0W8/s1o7updXfjec57vPZfzwX/lkRRqnEo3H1fRbgBRiZXUPnpfSJ1RiEmHqk0V5yFoCDSk4iT1/nnPrgGmf5W2ApUKYwpjQHi6JNtSSG3eEU331gccj2cUKFKkxlDHVpEXwKxpmqGDZx98/qsEjtdzBygS1f5gNDFQUtv9UiEF7LPt3M189VsCvjyNn0BpBOYc0fYVqDhtwG/QmJWOndwRQIeqTcPhHiAgV4LRno9WJq5WJq5lkZ4JhKsAiNzd6odvCrB+ll0EKoHxWd/3zrXnMwuLtxBGgbB1aLZ1W4CvqeagoJcAFGk5UjMwv9ZT0dC3IHBhKYRc/vbsXFnBgJyhXcB+EfqC0cTAZrcriSQHRbUf8Nu2fbsggJVpOgXaAMzlcrRvUOPSohptQBZonHoSP7323LUHH3rrvQc83ndL20pHMJK8ng8AYKVjHYhcUxjL+n4cWz1SV4Jir6dt6TlgPOv90VVIc4AZ2+4EGRE46p8vdm34SoLJVFO5aegI4DdUzxyu7X6+7qaZuAIEosl1L8DyaAeBOcchXFqX/ORK4NmjnYAfeLxR83wKRBOvQHqBvYYhN1wJrFRTFYa+BbK2I6HyusTkdgHgngLiVAUiPcMmAIZWLHv8pqETVia+k/6ArvqSMDBsAPjwpkDfuBw7lwO89i3Y6V3o9S/oD8hS33MyI4yYAAAAAElFTkSuQmCC');
            height: 24px;
            width: 24px;
            margin-right: 10px;
        }
    }
    
    &.error{
        border: solid 1px $red-validation;
        background-color: $notice-light-red;
        &::before{
            display: block;
            content: ' ';
            background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABiUlEQVRIidWVu04CURCGv0PExMSEGm8kBmKMJetTqICPIAVPIIkXChoDRp+AAh+AQvDyFC6ljRATL4GaWJiAYSzc3RwWdLls41/Nnjn7/zNzzpmB/w71l9PMxIOhXjiJIgXEgVVAgHegjlDtBNs1o1TvTSzQONhLouQCiHkE2UBUNnZ1WxtLQPL5QPPVPAOOPIgHfkMoRiNGTuXzfd0x5945BflPoIrj5osJcDLo0GCV5dq9PgEEJBUr398MCZiZeDD0FX7Eu+ZeeO5+LGxuVSpdgIC9GuqFkz6QA6zPL34m7I+A5tj3gdxiVclhAYXhF78S2bZt/RYt6Zti5buJDrqR3hXbFli2bb1Egn9w3oKTgYKWwMaoiKZAyzacDATMGQjdeBgSQKj6Ri84D80R6ATbNaDhA/2TxTUoYJTqPURlme2wBSSrt2/9FmG13PMZ6It6HxoSAIiuGacIBSbLRBAK0YiRczt+HzjpnQSoS8YZOMihO3JPARg5Mlcs1xtK1elLzWtk/n98A7v8gx/6hG+1AAAAAElFTkSuQmCC');
            height: 24px;
            width: 24px;
            margin-right: 10px;
            flex-shrink: 0;
        }
    }
}