.page {
    padding: 20px;

    .messages {
        max-height: calc(100vh - 145px);
        overflow-y: auto;

        .msgheader {
            display: flex;
            align-items: center;
            margin: 0 0 20px;
            >div {
                &:first-child {
                    margin-right: 10px;
                }
            }
            div{
                i{
                    font-size: .8em;
                    &.u{
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .message {

        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: solid 1px #cdcdcd;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        h1 {
            font-size: 2em;
            font-family: 'open sans';
            font-weight: normal;
            color: #1E4586;
            margin-bottom: 10px;
        }

        .messagebody {

            ul,
            ol {
                margin: 0 0 10px 18px;
            }
        }
    }
}

@media (max-width: 980px) {
    .page {
       
        .message{
            h1{
                font-size: 1.5em;
            }
        }
    }
}