@use "../../styles/config/colors" as *;

.container {
    margin: 20px 0 0 0;

    h3 {
        margin-bottom: 20px;
    }

    .text {
        max-width: 600px;
        font-size: .9em;
        line-height: 1.3em;
        margin: 0 0 10px;
    }

    .bar{
        height: 3px;
        background-color:$btn-green;
        margin:5px 0 0;
        transition: all linear 2s;

        &.collapse{
            transform:translate(-100%, 0);
        }
    }

    .confirmation {
        overflow: hidden;
        max-width: 600px;
    }

    .option {
        padding: 5px 0;
        display: flex;
        align-items: center;

        label {
            margin-left: 6px;
        }

        &.note {
            margin-top: 30px;
            display: block;

            label {
                display: block;
                margin: 0 0 5px 0;
            }

            textarea {
                width: 400px;
            }
        }
    }

    .buttons {
        button {
            margin-right: 5px;

            &:last-child {
                margin-right: unset;
            }
        }
    }
}