@use "../styles/config/colors" as *;

.warning{
    padding: 10px 0;
    display: flex;
    svg{
        color:$pink-dark;
        margin-right: 7px;
        flex-shrink: 0;
    }

    span{
        color:$pink-dark;
        line-height: 1.3em;
        font-size: .9em;
    }
}