@use '../../styles/config/colors' as *;

.container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: calc(100vh - 90px);
 

    .viewport {
        height: inherit;

        .split {
            display: flex;
            width: 100%;

            .left {
                width: 220px;
                flex-shrink: 0;
                height: calc(100vh - 90px);
                border-right: solid 1px #ccc;
            }

            .center {
                width: calc(100vw - 220px);
                height: inherit;
            }
        }
    }

}

.ck.ck-content.ck-editor__editable {
    min-height: 80px;
}


@media screen and (max-width: 600px) {

    .container {
        height: calc(100vh - 70px);

        .viewport {
            .split {
                display: block;
                height: inherit;

                .center {
                    width: 100vw;
                }

                .left {
                    display: none;
                }
            }
        }
    }
}