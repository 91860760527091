@use '../../styles/config/colors' as *;
@use '../../styles/config/shadows' as *;
@use '../../styles/mixins' as *;

.dropdown {
    position: absolute;
    width: 300px;
    background-color: $white;
    padding: 10px;
    right: 0;
    top: 0;
    z-index: 1000000;
    font-size: .9em;
    
    @include noselect;
    @include box-shadow;

    .s {
        padding: 4px 0;
    }

    .s1 {

        &.links {
            margin: 7px 0 5px 0;

            a {
                display: block;
                color: $blue-pen;
                margin: 0 0 5px 0;
            }
        }
    }

    .s {
        a {
            text-decoration: none;
            font-size: .88em;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .avatar {
        max-width: 100px;
        max-height: 100px;
        margin-right: 10px;
    }

    .user-icon {
        flex-grow: 0;
        height: 80px;
        width: 66px;
        background-color: $blue-extra-light;
        margin-right: 6px;
    }

    .side2 {
        width: 100%;
    }
}