@use './config/sizes' as *;

.image{
    display: inline-block;
    position: relative;

    .delete-button{

        cursor: pointer;
        position: absolute;
        z-index: 1000;
        top:3px;
        right:3px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA/klEQVRIie2UMU7DQBBF37BcwXUs3FC4Y32AbMQdcgcaSjpoKXOL3AFpRb9OlRRpjEyH5DOshiKKFCJLNqwUEcEvR7PzZv6XFs5dMqYp5DwJPB6Vn23Lw7cBdY6OXK5Xtv068yJl2N9Qb8g/zeHYfzhBBr8CsFWYAh8HtU6UGbBOBohyV7W8quEW6IBODe7mHS/KfTJAhWUoKKuGjRqcGlzVsAkFpQrLofeXQw1AJhFfXzGzzc6SOueayAuQJV+QqjGATg3OvrEOBWUoKG3L9iCTNIAo873nEvES8ftMRJknAxQWqwlOIp6d55lE/GqCU1gMLthX/P8qTgo4f30CFsJRUk26pxIAAAAASUVORK5CYII=');
        background-color: #fff;
        border-radius: 3px;
        opacity: .8;
        @include square(25px);

        &:hover{
            opacity: 1;
            filter: brightness(120%);
        }
    }
}
